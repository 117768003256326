import { ReactElement } from "react";
import "./CardContainer.css";
import { Typography } from "@mui/material";
import { subscribe } from "diagnostics_channel";
import { Subtitles } from "@mui/icons-material";
import React from "react";

export const CardContainer = function ({
  children,
  title,
  className,
  subTitle,
}: {
  children: React.ReactNode;
  title?: string | undefined;
  subTitle?: string | undefined;
  className?: string;
}): ReactElement {
  return (
    <div
      className={
        className !== undefined && className.length > 0
          ? "card-container " + className
          : "card-container"
      }
    >
      {title === undefined ? (
        <></>
      ) : (
        <>
          <Typography variant="h6" id="card-title">
            {title}
          </Typography>
          {subTitle === undefined ? (
            <></>
          ) : (
            <Typography id="card-subTitle">
              {subTitle}
            </Typography>
          )}
          <div className="spacer-small"></div>
        </>
      )}
      {children}
    </div>
  );
};
