import { ReactElement } from "react";

import SummarizeIcon from "@mui/icons-material/Summarize";
import CodeIcon from "@mui/icons-material/Code";
import MapIcon from "@mui/icons-material/Map";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import BadgeIcon from "@mui/icons-material/Badge";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ArticleIcon from "@mui/icons-material/Article";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import JoinFullRoundedIcon from "@mui/icons-material/JoinFullRounded";
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from "@mui/icons-material/Speed";

import { Logout } from "@mui/icons-material";
import { pageNames, appRoles } from "../authConfig";
import ApplicationHome from "../AuthorizationService/UI/Application/ApplicationHome";
import AuthorizationHome from "../AuthorizationService/UI/AuthorizationHome";
import { DevDashboard } from "../DevDashboard/UI/DevDashboard";
import EmployeeLookupView from "../EmployeeSync/UI/EmployeeLookupView";
import EmployeeSyncToPosErrorsView from "../ErrorReporting/UI/EmployeeSyncToPosErrorsView";
import HelpView from "../HelpPage/UI/HelpView";
import { LogoutPage } from "../Home/UI-components/LogoutPage";
import IntegrationsView from "../Integrations/UI/IntegrationsView";
import { ProductCatalog } from "../ProductCatalog/UI/ProductCatalog";
import DailyReporting from "../Reporting/UI/DailyReporting";
import StoreInfoView from "../Store411/UI/StoreInfoView";
import { Stores } from "../Store411/UI/Stores";
import StoreConfiguration from "../StoreConfiguration/UI/StoreConfiguration";
import React from "react";
import { DashboardHome } from "../Home/UI/DashboardHome";
import PaytronixErrorHome from "../PaytronixReporting/UI/PaytronixErrorHome";



export interface NavbarRoutes {
  name: string;
  icon: ReactElement | null;
  routerPath: string | null;
  control: ReactElement | null;
  roles: string[];
  isMenuItem: boolean;
  order: number;
  children: NavbarRoutes[] | null;
}

const MetaNavbarRoutes = {
  getRoutes: function (): NavbarRoutes[] {
    return [
      {
        name: pageNames.logout,
        icon: null,
        routerPath: "logout",
        control: <Logout />,
        roles: [],
        isMenuItem: false,
        order: 0,
        children: null,
      },
      {
        name: pageNames.logout,
        icon: null,
        routerPath: "logout-page",
        control: <LogoutPage />,
        roles: [],
        isMenuItem: false,
        order: 0,
        children: null,
      },
      {
        name: pageNames.home,
        icon: <SpeedIcon htmlColor={"#FFFFFF"}/>,
        routerPath: "",
        control: <DashboardHome />,
        roles: [],
        isMenuItem: true,
        order: 0,
        children: null,
      },
      {
        name: pageNames.integrations,
        icon: <JoinFullRoundedIcon htmlColor="#FFFFFF" />,
        routerPath: null,
        control: null,
        roles: [],
        isMenuItem: false,
        order: 200,
        children: [
          {
            name: pageNames.intStatus,
            icon: null,
            routerPath: "integration-statuses",
            control: <IntegrationsView />,
            roles: [appRoles.Integration.ViewStatus],
            isMenuItem: true,
            order: 1,
            children: [],
          },
          {
            name: pageNames.intEmployeeSync,
            icon: <ArticleIcon htmlColor="#FFFFFF" />,
            routerPath: "employee-sync-errors",
            control: (
              <EmployeeSyncToPosErrorsView />
            ),
            roles: [appRoles.Integration.ViewSyncErrorReport],
            isMenuItem: true,
            order: 2,
            children: [],
          },
          {
            name: pageNames.intPaytronixError,
            icon: <ArticleIcon htmlColor="#FFFFFF" />,
            routerPath: "paytronix-sync-errors",
            control: (
              <PaytronixErrorHome />
            ),
            roles: [appRoles.Paytronix.ErrorView],
            isMenuItem: true,
            order: 3,
            children: [],
          },
        ],
      },
      {
        name: pageNames.helpPage,
        icon: <HelpCenterIcon htmlColor="#FFFFFF" />,
        routerPath: "help",
        control: <HelpView />,
        roles: [],
        isMenuItem: false,
        order: 0,
        children: null,
      },
      {
        name: pageNames.managePOSEmployee,
        icon: <BadgeIcon htmlColor="#FFFFFF" />,
        routerPath: "employee-lookup",
        control: <EmployeeLookupView/>,
        roles: [appRoles.EmployeeLookup],
        isMenuItem: true,
        order: 100,
        children: [],
      },
      {
        name: pageNames.reports,
        icon: <SummarizeIcon htmlColor="#FFFFFF" />,
        routerPath: "reports",
        control: <DailyReporting />,
        roles: [appRoles.Reporting],
        isMenuItem: true,
        order: 300,
        children: [],
      },
      {
        name: pageNames.store411,
        icon: <HolidayVillageIcon htmlColor="#FFFFFF" />,
        routerPath: "store-411",
        control: <Stores />,
        roles: [appRoles.StoreInfo.ViewStores],
        isMenuItem: true,
        order: 500,
        children: [
          {
            name: pageNames.store411Info,
            icon: null,
            routerPath: "store-411/:storeId",
            control: <StoreInfoView />,
            roles: [appRoles.StoreInfo.ViewStores],
            isMenuItem: false,
            order: 1,
            children: [],
          },
        ],
      },
      {
        name: pageNames.productCatalog,
        icon: <MenuBookIcon htmlColor="#FFFFFF" />,
        routerPath: "products",
        control: <ProductCatalog />,
        roles: [appRoles.ProductCatalog],
        isMenuItem: true,
        order: 600,
        children: [],
      },
      {
        name: pageNames.devDashboard,
        icon: <CodeIcon htmlColor="#FFFFFF" />,
        routerPath: "devDashboard",
        control: <DevDashboard />,
        roles: [appRoles.DevDashboard],
        isMenuItem: true,
        order: 700,
        children: [],
      },
      {
        name: pageNames.storeConfiguration,
        icon: <MapIcon htmlColor="#FFFFFF" />,
        routerPath: "storeConfiguration",
        control: <StoreConfiguration />,
        roles: [appRoles.Config.ViewIPScheme],
        isMenuItem: true,
        order: 800,
        children: [],
      },
      {
        name: pageNames.authorization,
        icon: <SecurityIcon htmlColor="#FFFFFF" />,
        routerPath: "authorization",
        control: <AuthorizationHome />,
        roles: [appRoles.Authorization.Admin],
        isMenuItem: true,
        order: 900,
        children: [{
          name: pageNames.appInfo,
          icon: null,
          routerPath: "authorization/:appName",
          control: <ApplicationHome />,
          roles: [appRoles.Authorization.Admin],
          isMenuItem: false,
          order: 1,
          children: [],
        },],
      },
    ];
  },
};

export default MetaNavbarRoutes;
