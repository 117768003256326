import { AuthenticatedTemplate } from "@azure/msal-react";
import { ReactElement, useContext, useEffect } from "react";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import { pageNames } from "../../authConfig";
import { PageContainer } from "../../Common/UI/components/PageContainer";
import PaytronixSyncErrors from "./PaytronixSyncErrors";

function PaytronixErrorHome(): ReactElement {
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.integrations, pageNames.intPaytronixError],
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <PageContainer>
        <PaytronixSyncErrors />
      </PageContainer>
    </AuthenticatedTemplate>
  );
}

export default PaytronixErrorHome;
