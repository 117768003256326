import ReactDOM, { Container } from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Common/UI/styles/theme";
import App from "./App/App";

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { config } from "./authConfig";
import { CssBaseline } from "@mui/material";

export const msalInstance: PublicClientApplication =
  new PublicClientApplication(config.msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    //@ts-ignore
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload?.account) {
      //@ts-ignore
      const account = event.payload?.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const container: Container | null = document.getElementById(
    "root"
  ) as Container;
  const root = ReactDOM.createRoot(container);

  root.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App instance={msalInstance} />
    </ThemeProvider>
  );
});
