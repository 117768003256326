import { Typography } from "@mui/material";

export default function Store411Help() {
  return (
    <>
      <Typography variant="body1" gutterBottom>
        Store 411 is your directory for key information about our stores. On our landing page, you will see all available stores and basic information
        in grid format.
      </Typography>
      <ul>
        <li>
          COLUMNS button: Click Columns Button to Add/Remove additional data in grid format. Please note, you will only be able to see data that is
          permitted under your Security role.{" "}
        </li>
        <li>
          Filter Data: Click on burger line in the column header and provide filter criteria for that column. You can apply multiple filters, one per
          column.{" "}
        </li>
        <li>EXPORT AS CSV: Click on this button to export all data available with the columns and filtering available.</li>
        <li>For more details, click on the Store Number hyperlink </li>
      </ul>

      <a href="/store-411">BASIC INFO Tab</a>
      <Typography variant="body1" gutterBottom>
        Provides Key information about the store including: Status, Store Number, Store Alternate Name, Store Email, Store Open Date, Store Address,
        Store Phone Number, Store Direct Phone Number, Store Region, Store District (Market), Store Date Closed, and Store Time Zone
        <p></p>
      </Typography>

      <a href="/store-411">NETWORK Tab</a>
      <Typography variant="body1" gutterBottom>
        For each Connection (1 & 2) displays below data points:
        <div>
          - IP Address, Physical Interface, Type, Billing, ISP, Support Name, Support Phone Number, Support Email, Static IP, Subnet, Gateway, Notes.
        </div>
        <div>- Special roles will have the ability to Edit Network information. To do so, click on EDIT button, update data, select SAVE.</div>
        <p></p>
      </Typography>

      <a href="/store-411">PEOPLE Tab</a>
      <Typography variant="body1" gutterBottom>
        This tab provides list of store Market Partner, Managing Partner & Salaried Managers, their names, email addresses and MP phone number.<p></p>
      </Typography>

      <a href="/store-411">BUILDING PROPERTIES</a>
      <Typography variant="body1" gutterBottom>
        This tab provides details related to the Store building properties like: Store SQFT, Store Gen (Generation 1st vs 2nd), Pickup window Yes/No
        <p></p>
      </Typography>

      <a href="/store-411">CF HOO</a>
      <Typography variant="body1" gutterBottom>
        This tab provides the list of Stores Hours of Operations per day as setup in CardFree platform.
        <p></p>
      </Typography>
      <a href="/store-411">POS Terminals</a>
      <Typography variant="body1" gutterBottom>
        This tab provides the list of Active POS Terminals per store per what is available in Bomgar.
        <p></p>
      </Typography>
      <a href="/store-411">AV Tab</a>
      <Typography variant="body1" gutterBottom>
        This tab provides all Audio & Video information about a store including: total number of TVs in different dining areas, Amp Model, Zone
        Controller Model, # of Audio Zones, Music Device Player ID, Atmosphere TV Device Serial #, TV Provider, and TV Provider Account #. You should
        be able to Edit this page only if you have the correct role.
        <p></p>
      </Typography>
    </>
  );
}
