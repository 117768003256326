import {
  AppBar,
  Breadcrumbs,
  Link,
  Toolbar,
  useMediaQuery,
  Theme,
  Box,
} from "@mui/material";
import {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { sizing } from "../../Common/UI/styles/sizing";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import WelcomeName from "./WelcomeName";
import { SignInButton } from "./SignInButton";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";

export const BreadcrumbsBar = (): ReactElement => {
  const { state, setState } = useContext(ApplicationLocationContext);
  const [steps, setSteps] = useState<ReactNode[]>([]);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  ); // Detect mobile devices

  useEffect(() => {
    let newSteps: ReactNode[] = [];
    let homeColor = state.path.length > 0 ? "#000000" : "primary";
    newSteps.push(
      <Link key={"home"} href="/" color={homeColor} underline="none">
        Dashboard Home
      </Link>
    );
    for (let i = 0; i < state.path.length; i++) {
      let currentColor = i !== state.path.length - 1 ? "#000000" : "primary";
      const currentStep = state.path[i];
      newSteps.push(
        <Link key={currentStep} href="#" color={currentColor} underline="none">
          {currentStep}
        </Link>
      );
    }
    setSteps(newSteps);
  }, [state.path]);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: isMobile ? "100%" : `calc(100% - ${sizing.drawerWidth}px)`,
        ml: isMobile ? "0px" : `${sizing.drawerWidth}px`,
        paddingTop: isMobile ? "10px" : "20px",
        paddingBottom: isMobile ? "10px" : "20px",
        paddingLeft: isMobile ? "25px" : "28px",
        paddingRight: isMobile ? "25px" : "28px",
        boxShadow: "0px 10px 20px 0px #0000000A",
        height: isMobile ? "70px" : "unset",
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "52px",
          width: "100%",
        }}
      >
        <Breadcrumbs
          separator=">"
          aria-label="breadcrumb"
          sx={{
            fontSize: isMobile ? "14px" : "18px",
            flexShrink: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: isMobile ? "60%" : "auto",
          }}
        >
          {steps}
        </Breadcrumbs>

        <Box
          sx={{
            display: isMobile ? "flex" : "unset",
            overflow: isMobile ? "hidden" : "unset",
          }}
        >
          <AuthenticatedTemplate>
            <WelcomeName />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <SignInButton />
          </UnauthenticatedTemplate>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
