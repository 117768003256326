import { useEffect, useState } from "react";
import { config } from "../../../authConfig";
import { useMsal } from "@azure/msal-react";

const useUserRoles = () => {
  const { instance, inProgress } = useMsal();
  const [roles, setRoles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

   useEffect(() => {
    if (instance.getActiveAccount() !== null) {
      let account = instance.getActiveAccount();
      fetchRoles(account?.username || "");
    }
  }, [instance]);

  const fetchRoles = function (email: string) {
    setIsLoading(true);
    const url = config.rootAPIList.allUserPermissions
      .replace("{applicationName}", config.applicationName)
      .replace("{email}", email);

      fetch(`${config.rootAPIUrl}${url}`, { method: "GET"})
      .then(async (value) => {
        if (value.ok) {
          var result = await value.json();
          var res = result.map((x: any) => x.name);
          setRoles(res);
        } else {
          var nok = await value.text();
          setRoles([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setRoles([]);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  return { roles, isLoading };
};

export default useUserRoles;
