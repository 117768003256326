import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CardContainer } from "../../Common/UI/components/CardContainer";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import React from "react";
import { LoadingIndicator } from "../../Common/UI/components/Loading";

export interface SimpleDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children: ReactNode;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, children, title } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} className="simple-dialog">
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <>Delete</>
          </Grid>
          <Grid item xs={2} className="close-icon-container">
            <CloseIcon onClick={onClose} className="close-btn" />
          </Grid>
        </Grid>
      </DialogTitle>
      {children}
    </Dialog>
  );
}

export default function DeleteConfirmation({
  message,
  deleteHandler,
  item,
}: {
  message: string;
  deleteHandler: (item: { name: string }) => Promise<void>;
  item: { name: string };
}): ReactElement {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "baseline",
          }}
        >
          <Button
            className={"create-app-btn"}
            onClick={() => {
              handleClickOpen();
            }}
          >
            <DeleteRoundedIcon />
          </Button>
        </Grid>
      </Grid>
      <SimpleDialog
        title="Delete"
        key="create-application-key"
        open={open}
        onClose={handleClose}
      >
        <CardContainer className="application-dialog">
          <LoadingIndicator show={loading}>
            <Typography>{message}</Typography>
          </LoadingIndicator>
          <div className="spacer-small"></div>
          <Grid container className={"application-item"}>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Button
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  deleteHandler(item);
                  setLoading(false);
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </CardContainer>
      </SimpleDialog>
    </Box>
  );
}
