import { AuthenticatedTemplate } from "@azure/msal-react";
import { ReactElement, useContext, useEffect } from "react";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import { pageNames } from "../../authConfig";
import { PageContainer } from "../../Common/UI/components/PageContainer";
import IntegrationStatuses from "../../Common/UI/components/IntegrationStatuses";

function IntegrationsView(): ReactElement {
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.integrations, pageNames.intStatus],
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <PageContainer>
        <IntegrationStatuses showDateFilter={true}></IntegrationStatuses>
      </PageContainer>
    </AuthenticatedTemplate>
  );
}

export default IntegrationsView;
