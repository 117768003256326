import { ReactElement, SetStateAction } from "react";
import { PageContainer } from "../../../Common/UI/components/PageContainer";
import { CardContainer } from "../../../Common/UI/components/CardContainer";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { config } from "../../../authConfig";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import { LoadingIndicator } from "../../../Common/UI/components/Loading";
import { theme } from "../../../Common/UI/styles/theme";
import LRSGrid, { LRSData } from "./LRSGrid";
import "./ReportingPage.css";
import CrunchTimeWeeklySalesDiffGrid, { CrunchTimeDateData } from "./CrunchTimeWeeklySalesDiffGrid";
import { Cell } from "./CrunchTimeReportInterfaces/Cell";
import { Snackbar, Alert } from "@mui/material";

function ReportingPage(): ReactElement {
  const ReportTypes: { name: string; key: number }[] = [
    { name: "LRS", key: 1 },
    { name: "CrunchTime Weekly Sales Diff", key: 2 },
  ];

  const execute = useFetchMsal();
  const [searchDate, setSearchDate] = useState<Dayjs | null>(dayjs().add(-1, "day"));
  const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs().add(-1, "day"));
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs().add(-1, "day"));

  const [dateError, setDateError] = useState<boolean>(false);
  const [fromDateError, setFromDateError] = useState<boolean>(false);
  const [toDateError, setToDateError] = useState<boolean>(false);

  const [selectedType, setSelectedType] = useState<number>(ReportTypes[0].key);
  const [lrsData, setLRSData] = useState<LRSData[] | undefined>(undefined);
  const [salesDiffCrunchTimeData, setSalesDiffCrunchTimeData] = useState<CrunchTimeDateData[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string>("");

  const [coloredCells, setColoredCells] = useState<Cell[]>([]);

  const [addedCells, setAddedCells] = useState<Cell[]>([]);
  const [removedCells, setRemovedCells] = useState<Cell[]>([]);

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  const saveColoredCells = () => {
    const crunchTimeReportAddCellUrl = config.rootAPIList.crunchTimeReportsAddColoredCells;
    const crunchTimeReportDeleteCellUrl = config.rootAPIList.crunchTimeReportsDeleteColoredCells;

    setLoading(true);

    let addPromise: Promise<{ success: boolean; message: string }> = Promise.resolve({ success: true, message: "No added cells to save." });
    let deletePromise: Promise<{ success: boolean; message: string }> = Promise.resolve({ success: true, message: "No removed cells to delete." });

    if (addedCells.length > 0) {
      addPromise = execute("POST", `${config.rootAPIUrl}${crunchTimeReportAddCellUrl}`, JSON.stringify(addedCells))
        .then(() => {
          console.log("Added cells saved successfully!");
          setAddedCells([]);
          return { success: true, message: "Added cells saved successfully!" };
        })
        .catch((error) => {
          console.error("Error saving added cells:", error);
          return { success: false, message: "Error saving added cells!" };
        });
    }

    if (removedCells.length > 0) {
      deletePromise = execute("DELETE", `${config.rootAPIUrl}${crunchTimeReportDeleteCellUrl}`, JSON.stringify(removedCells))
        .then(() => {
          console.log("Removed cells deleted successfully!");
          setRemovedCells([]);
          return { success: true, message: "Removed cells deleted successfully!" };
        })
        .catch((error) => {
          console.error("Error deleting removed cells:", error);
          return { success: false, message: "Error deleting removed cells!" };
        });
    }

    // Wait for both requests to complete
    Promise.allSettled([addPromise, deletePromise]).then((results) => {
      const messages = results
        .filter((r) => r.status === "fulfilled")
        .map((r) => (r as PromiseFulfilledResult<{ success: boolean; message: string }>).value.message)
        .join("\n");

      const hasErrors = results.some(
        (r) => r.status === "fulfilled" && !(r as PromiseFulfilledResult<{ success: boolean; message: string }>).value.success
      );

      setSnackbarMessage(messages);
      setSnackbarSeverity(hasErrors ? "error" : "success");
      setSnackbarOpen(true);

      setLoading(false);
    });
  };

  const search = async function () {
    if (searchDate) {
      const date = searchDate.format("YYYY-MM-DD");
      setGeneralError("");

      switch (selectedType) {
        case 1:
          const url = config.rootAPIList.dailyReports.replace("{name}", "lrs").replace("{date}", date);
          callApi(url, setLRSData, setLoading);
          break;
        case 2:
          if (fromDate && toDate) {
            setLoading(true);
            const salesDiffCrunchTimeUrl = config.rootAPIList.salesDiffCrunchTimeReports
              .replace("{startDate}", fromDate.format("YYYY-MM-DD"))
              .replace("{endDate}", toDate.format("YYYY-MM-DD"));

            const crunchTimeColoredCellsUrl = config.rootAPIList.crunchTimeReportsColoredCells
              .replace("{startDate}", fromDate.format("YYYY-MM-DD"))
              .replace("{endDate}", toDate.format("YYYY-MM-DD"));

            try {
              await Promise.all([
                callSalesDiffCrunchTimeApi(salesDiffCrunchTimeUrl, setSalesDiffCrunchTimeData),
                callCrunchTimeColoredCellsAPI(crunchTimeColoredCellsUrl, setColoredCells),
              ]);
            } catch (error) {
              console.error("Error fetching data", error);
            } finally {
              setLoading(false);
            }
          } else {
            console.error("fromDate or toDate is null");
          }
          break;

        default:
          break;
      }
    }
  };

  const callApi = (url: string, setData: (value: SetStateAction<any | undefined>) => void, setLoading: (value: SetStateAction<boolean>) => void) => {
    setLoading(true);
    setData([]);
    execute("GET", `${config.rootAPIUrl}${url}`)
      .then((result) => {
        setData(JSON.parse(result.data));
      })
      .catch((error) => {
        setGeneralError("Report for this date not available!");
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callSalesDiffCrunchTimeApi = async (url: string, setData: (value: SetStateAction<any | undefined>) => void) => {
    setData([]);
    try {
      const result = await execute("GET", `${config.rootAPIUrl}${url}`);
      setData(result);
    } catch (error) {
      setGeneralError("Report for this date not available!");
      console.error(error);
    }
  };

  const callCrunchTimeColoredCellsAPI = async (url: string, setData: (value: SetStateAction<any | undefined>) => void) => {
    setData([]);
    try {
      const result = await execute("GET", `${config.rootAPIUrl}${url}`);
      const formattedResult = result.map(({ id, rowId, field }: any) => ({
        RowId: rowId.toString(),
        Field: field,
      }));
      setData(formattedResult);
    } catch (error) {
      setGeneralError("Report for this date not available!");
      console.error(error);
    }
  };

  return (
    <PageContainer>
      <CardContainer title="Reports" className="reports">
        <Grid container sx={{ maxWidth: "800px" }}>
          <Grid item xs={selectedType === 1 ? 5 : 3} sx={{ pr: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="report-label">Report Type</InputLabel>
              <Select
                labelId="report-label"
                id="report-type"
                value={selectedType + ""}
                label="Age"
                onChange={(event: SelectChangeEvent) => {
                  setGeneralError("");
                  setSelectedType(event.target.value as unknown as number);
                }}
              >
                {ReportTypes.map((x) => (
                  <MenuItem key={x.key} value={x.key}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {selectedType === 1 ? (
            <Grid item xs={5} sx={{ pr: "10px" }}>
              <DatePicker
                sx={{ width: "100%" }}
                onError={(newError) => {
                  if (newError !== null) {
                    setDateError(true);
                  }
                }}
                label={"Business Date"}
                value={searchDate}
                onChange={(newValue) => {
                  setSearchDate(newValue);
                  setDateError(false);
                }}
                disableFuture
              />
            </Grid>
          ) : (
            <></>
          )}

          {selectedType === 2 ? (
            <>
              <Grid item xs={3} sx={{ pr: "10px" }}>
                <DatePicker
                  sx={{ width: "100%" }}
                  onError={(newError) => {
                    if (newError !== null) {
                      setFromDateError(true);
                    }
                  }}
                  label={"From"}
                  value={fromDate}
                  onChange={(newValue) => {
                    setFromDate(newValue);
                    setFromDateError(false);
                    if (toDate && newValue && toDate.isBefore(newValue)) {
                      setToDate(null);
                    }
                  }}
                  minDate={dayjs("2023-04-12")}
                  maxDate={dayjs().add(-1, "day")}
                  disableFuture
                />
              </Grid>
              <Grid item xs={3} sx={{ pr: "10px" }}>
                <DatePicker
                  sx={{ width: "100%" }}
                  onError={(newError) => {
                    if (newError !== null) {
                      setToDateError(true);
                    }
                  }}
                  label={"To"}
                  value={toDate}
                  onChange={(newValue) => {
                    setToDate(newValue);
                    setToDateError(false);
                  }}
                  minDate={fromDate || dayjs("2023-04-12")}
                  maxDate={dayjs().add(-1, "day")}
                  disableFuture
                />
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Grid
            item
            xs={selectedType === 1 ? 2 : 3}
            sx={{
              textAlign: "center",
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Button onClick={search} disabled={dateError || fromDateError || toDateError || loading} variant="contained" color="primary">
              Load
            </Button>

            {selectedType === 2 && (
              <Button onClick={saveColoredCells} disabled={loading || coloredCells.length === 0} variant="contained" color="primary">
                Save
              </Button>
            )}
          </Grid>
        </Grid>
        <Box>
          <Typography color={theme.palette.error.main}>{generalError}</Typography>
        </Box>
        <div className="spacer-small"></div>

        <LoadingIndicator show={loading}>
          {selectedType !== 1 ? <></> : <LRSGrid lrsData={lrsData} />}
          {selectedType !== 2 ? (
            <></>
          ) : (
            <CrunchTimeWeeklySalesDiffGrid
              crunchTimeDatesData={salesDiffCrunchTimeData}
              coloredCellsChange={coloredCells}
              onColoredCellsChange={setColoredCells}
              setAddedCellsParent={setAddedCells}
              setRemovedCellsParent={setRemovedCells}
            />
          )}
        </LoadingIndicator>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </CardContainer>
    </PageContainer>
  );
}
export default ReportingPage;
