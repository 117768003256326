import "./NavBar.css";
import Toolbar from "@mui/material/Toolbar";
import { SetStateAction, useContext, useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  useMediaQuery,
  Theme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu"; // Import the Menu icon for the toggle button
import { ReactElement } from "react";
import { sizing } from "../../Common/UI/styles/sizing";
import logo from "./img/DDashLogo.jpg";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import React from "react";
import { NavbarRoutes } from "../../App/MetaNavbarRoutes";
import { LoadingIndicator } from "../../Common/UI/components/Loading";


const NavBar = ({
  authorizedMenuItems,
  isLoading
}: {
  authorizedMenuItems: NavbarRoutes[];
  isLoading:boolean;
}): ReactElement => {
  const [open, setOpen] = useState<{ [name: string]: boolean }>({});
  const [currentView, setCurrentView] = useState<string>("");
  const { state, setState } = useContext(ApplicationLocationContext);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  ); // Detect mobile devices
  const [drawerOpen, setDrawerOpen] = useState(!isMobile); // Drawer is open by default on desktop, closed on mobile

  useEffect(() => {
    const initialState: SetStateAction<{ [name: string]: boolean }> = {};

    const setInitialState = function (navItem: NavbarRoutes) {
      initialState[navItem.name] = false;
      navItem.children?.map((x) => {
        setInitialState(x);
      });
    };

    setOpen(initialState);
    authorizedMenuItems.sort((a, b) => a.order - b.order);
  }, [authorizedMenuItems]);

  useEffect(() => {
    if (state.path.length > 0) {
      setOpen((o) => ({ ...o, [state.path[0]]: true }));
      setCurrentView(state.path[state.path.length - 1]);
    } else {
      const initialState: SetStateAction<{ [name: string]: boolean }> = {};
      const setInitialState = function (navItem: NavbarRoutes) {
        initialState[navItem.name] = false;
        navItem.children?.map((x) => {
          setInitialState(x);
        });
      };
      setOpen(initialState);
      setCurrentView("");
    }
  }, [state.path, authorizedMenuItems]);

  const handleClick = (itemName: string | undefined) => {
    if (itemName !== undefined)
      setOpen((o) => ({ ...open, [itemName]: !o[itemName] }));
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer}
          sx={{ position: "fixed", top: 16, left: 16, zIndex: 1200 }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        sx={{
          width: sizing.drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: sizing.drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "black",
            color: "white",
          },
        }}
        variant={isMobile ? "temporary" : "permanent"}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Toolbar>
          <a href="/">
            <img className="logo" src={logo} alt="Torchy's Logo" />
          </a>
        </Toolbar>
        <Divider />
        <LoadingIndicator show={isLoading}>
        <List component="nav">
          {authorizedMenuItems.map((menuItem, i) => {
            return (
              <Box key={i}>
                {menuItem.isMenuItem === true ? (
                  <ListItemButton
                    className="top-lvl-item"
                    key={menuItem.name}
                    component="a"
                    href={"/" + menuItem.routerPath}
                    selected={menuItem.name === currentView}
                  >
                    <ListItemIcon>{menuItem.icon}</ListItemIcon>
                    <ListItemText disableTypography primary={menuItem.name} />
                  </ListItemButton>
                ) : (
                  <>
                    <ListItemButton
                      key={menuItem.name}
                      onClick={() => handleClick(menuItem.name)}
                    >
                      <ListItemIcon>{menuItem.icon}</ListItemIcon>
                      <ListItemText disableTypography primary={menuItem.name} />
                    </ListItemButton>
                    <Collapse
                      in={open[menuItem.name!]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List disablePadding className="nav-children">
                        {menuItem.children?.map((child) => {
                          return (
                            <ListItemButton
                              key={menuItem.name + child.name!}
                              component="a"
                              href={"/" + child.routerPath}
                              sx={{ pl: 4 }}
                              selected={child.name === currentView}
                            >
                              <ListItemText
                                disableTypography
                                primary={child.name}
                              />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </Collapse>
                  </>
                )}
              </Box>
            );
          })}
        </List>
        </LoadingIndicator>
      </Drawer>
    </>
  );
};

export default NavBar;
