import {
  Box,
  Button,
  Checkbox,
  darken,
  FormControlLabel,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from "@mui/material";
import React, {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./StoreConfigurationInfo.css";
import { appRoles, config, pageNames } from "../../authConfig";
import { CardContainer } from "../../Common/UI/components/CardContainer";
import { LoadingIndicator } from "../../Common/UI/components/Loading";
import useIsAuthorized from "../../Common/UI/hook/useIsAuthorized";

import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { GridLoadingOverlay } from "@mui/x-data-grid";
import { theme } from "../../Common/UI/styles/theme";
import DownloadIcon from "@mui/icons-material/Download";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import SaveIcon from "@mui/icons-material/Save";
import styles from "./StoreConfiguration.module.css";
import dayjs from "dayjs";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import { CustomTabPanel } from "../../Common/UI/components/CustomTabPanel";
import useUserRoles from "../../Common/UI/hook/useUserRoles";

interface IPSchemeData {
  id: number;
  deviceType: string;
  deviceName: string;
  ipAddress: string;
  ipRange: string;
  subnetMask: string;
  gateway: string;
  dns1: string;
  dns2: string;
  modified: boolean;
}

interface IPSchemeUpdate {
  id: number;
  deviceName: string;
  ipAddress: string;
  subnetMask: string;
  gateway: string;
  dns1: string;
  dns2: string;
}

function StoreConfiguration(): ReactElement {
  const { setState } = useContext(ApplicationLocationContext);
  const gridRef = useRef<AgGridReact>(null);
  const saveRef = useRef<HTMLButtonElement>(null);

  //tabs
  const [value, setValue] = useState(0);
  const { roles } = useUserRoles();
  const showPosIPScheme = useIsAuthorized(
    [appRoles.Config.ViewIPScheme],
    roles
  );
  const allowEdit = useIsAuthorized([appRoles.Config.EditIPScheme], roles);

  const [loading, setLoading] = useState<boolean>(true);
  const [IPSchemeData, setIPSchemeData] = useState<IPSchemeData[]>();
  const [menuAnchorElement, setMenuAnchorElement] =
    useState<HTMLElement | null>(null);

  const [colsDef, setColsDef] = useState<ColDef[]>([
    { field: "deviceType", headerName: "Device Type" },
    { field: "ipRange", headerName: "IP Range" },
    { field: "deviceName", headerName: "Device Name" },
    { field: "ipAddress", headerName: "IP Address", editable: allowEdit },
    { field: "subnetMask", headerName: "Subnet Mask", editable: allowEdit },
    { field: "gateway", headerName: "Gateway", editable: allowEdit },
    { field: "dns1", headerName: "DNS 1", editable: allowEdit },
    { field: "dns2", headerName: "DNS 2", editable: allowEdit },
  ]);

  useEffect(() => {
    setColsDef((prevCols) =>
      prevCols.map((col) =>
        ["ipAddress", "subnetMask", "gateway", "dns1", "dns2"].includes(
          col.field!
        )
          ? { ...col, editable: allowEdit }
          : col
      )
    );
    gridRef.current?.api.refreshHeader();
  }, [allowEdit]);

  const execute = useFetchMsal();

  const handleExportAsCsvButtonClick = () => {
    gridRef.current?.api.exportDataAsCsv({
      fileName: `export_${dayjs(dayjs())
        .format("YYYY_MM_DD_HH_mm_ss")
        .toString()}`,
    });
  };

  const fetchStoreConfigurationInfo = async () => {
    try {
      setLoading(true);
      const url = config.rootAPIList.storeConfiguration;
      const response = await execute("GET", `${config.rootAPIUrl}${url}`);

      const mappedData: IPSchemeData[] = response.map((item: any) => ({
        id: item.id,
        deviceType: item.deviceType,
        deviceName: item.deviceName,
        ipAddress: item.ipAddress,
        ipRange: item.ipRange,
        subnetMask: item.subnetMask,
        gateway: item.gateway,
        dns1: item.dnS1,
        dns2: item.dnS2,
        modified: false,
      }));

      setIPSchemeData(mappedData);
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleColumnsMenuChange = (e: ChangeEvent<HTMLInputElement>) => {
    setColsDef((prev) =>
      prev.map((col: ColDef) =>
        col.field === e.target.name ? { ...col, hide: !col.hide } : col
      )
    );
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    gridRef.current?.api.stopEditing();
    let modifiedRows: IPSchemeUpdate[] = [];
    gridRef.current?.api.forEachNode((node) => {
      if (node.data.modified) {
        modifiedRows.push({
          id: node.data.id,
          deviceName: node.data.deviceName,
          ipAddress: node.data.ipAddress,
          subnetMask: node.data.subnetMask,
          gateway: node.data.gateway,
          dns1: node.data.dns1,
          dns2: node.data.dns2,
        });
      }
    });

    try {
      const url = config.rootAPIList.updateStoreConfigurationIpScheme;
      await execute("PUT", `${config.rootAPIUrl}${url}`, modifiedRows);
      await fetchStoreConfigurationInfo();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setState(() => ({ path: [pageNames.storeConfiguration] }));
    fetchStoreConfigurationInfo();
  }, []);

  return (
    <div className={styles.storeConfigurationContainer}>
      <CardContainer title="Store Configuration" className="store-info">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleTabChange} aria-label="">
              <Tab
                sx={showPosIPScheme ? {} : { display: "none" }}
                label="POS IP Scheme"
                id={`simple-tab-1`}
                aria-controls={`simple-tabpanel-1`}
              />
            </Tabs>
          </Box>

          <div className="spacer-small"></div>

          {/* panel 1 */}
          {showPosIPScheme ? (
            <CustomTabPanel value={value} index={0}>
              <LoadingIndicator show={loading}>
                {(IPSchemeData?.length ?? 0) > 0 ? (
                  <>
                    <div>
                      <Box sx={{ display: "flex", mb: 1 }}>
                        <Button
                          variant="contained"
                          startIcon={<ViewWeekIcon />}
                          sx={{
                            mb: 1,
                            mr: 1,
                            backgroundColor: theme.palette.secondary.main,
                            "&:hover": {
                              backgroundColor: darken(
                                theme.palette.secondary.main,
                                0.1
                              ),
                            },
                          }}
                          disableElevation
                          onClick={(e) => setMenuAnchorElement(e.currentTarget)}
                        >
                          Columns
                        </Button>
                        <Button
                          sx={{
                            mb: 1,
                            mr: 1,
                            width: "200px",
                            backgroundColor: theme.palette.secondary.main,
                            "&:hover": {
                              backgroundColor: darken(
                                theme.palette.secondary.main,
                                0.1
                              ),
                            },
                          }}
                          startIcon={<DownloadIcon />}
                          onClick={handleExportAsCsvButtonClick}
                        >
                          Export as CSV
                        </Button>
                        {allowEdit && (
                          <Button
                            sx={{
                              mb: 1,
                              ml: "auto",
                              width: "200px",
                              backgroundColor: theme.palette.secondary.main,
                              "&:hover": {
                                backgroundColor: darken(
                                  theme.palette.secondary.main,
                                  0.1
                                ),
                              },
                            }}
                            startIcon={<SaveIcon />}
                            onClick={handleSaveChanges}
                            ref={saveRef}
                          >
                            Save changes
                          </Button>
                        )}
                        <Menu
                          open={Boolean(menuAnchorElement)}
                          anchorEl={menuAnchorElement}
                          onClose={() => setMenuAnchorElement(null)}
                        >
                          {colsDef.map((col: ColDef) => (
                            <MenuItem key={col.field}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!col.hide}
                                    name={col.field}
                                    onChange={handleColumnsMenuChange}
                                  />
                                }
                                label={col.headerName}
                              />
                            </MenuItem>
                          ))}
                        </Menu>
                      </Box>
                    </div>
                    <div id={styles.devicesGrid} className="ag-theme-quartz">
                      <AgGridReact
                        columnDefs={colsDef}
                        rowData={IPSchemeData || []}
                        onCellValueChanged={(e) => {
                          e.data.modified = true;
                        }}
                        loading={loading}
                        loadingOverlayComponent={GridLoadingOverlay}
                        ref={gridRef}
                        pagination
                        paginationPageSize={20}
                        defaultColDef={{
                          headerClass: "tableHeader",
                          filter: "agTextColumnFilter",
                          filterParams: {
                            buttons: ["apply", "clear"],
                            closeOnApply: true,
                            maxNumConditions: 4,
                          },
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </LoadingIndicator>
            </CustomTabPanel>
          ) : (
            <></>
          )}
        </Box>
      </CardContainer>
    </div>
  );
}
export default StoreConfiguration;
