import Grid from "@mui/material/Unstable_Grid2";
import NavBar from "../Home/UI-components/NavBar";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { useEffect, useState } from "react";
import {
  EventType,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { ReactElement } from "react";
import { config, loginRequest } from "../authConfig";

import Box from "@mui/material/Box";
import { ApplicationState } from "../Common/UI/utils/ApplicationState";
import { BreadcrumbsBar } from "../Home/UI-components/BreadcrumbsBar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MainController } from "./MainController";
import { NavbarRoutes } from "./MetaNavbarRoutes";
import React from "react";
import { LoadingIndicator } from "../Common/UI/components/Loading";

const App = ({
  instance,
}: {
  instance: PublicClientApplication;
}): ReactElement => {
  const [authorizedMenuItems, setAuthorizedMenuItems] = useState<
    NavbarRoutes[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mainCtrl = MainController();
  const router = createBrowserRouter(mainCtrl.reactNavigationRoutes);

  const load = () => {
    if (instance.getActiveAccount() !== null) {
      let account = instance.getActiveAccount();
      setIsLoading(true);
      const url = config.rootAPIList.allUserPermissions
        .replace("{applicationName}", config.applicationName)
        .replace("{email}", account?.username || "");

      fetch(`${config.rootAPIUrl}${url}`, { method: "GET" })
        .then(async (value) => {
          if (value.ok) {
            var result = await value.json();
            var res = result.map((x: any) => x.name);
            setAuthorizedMenuItems(mainCtrl.getAuthorizedMenuItems(res));
          } else {
            var nok = await value.text();
            console.log(nok);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    load();
  }, [instance]);

  instance.addEventCallback((event) => {
    //@ts-ignore
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload?.account) {
      load();
    }
  });

  return (
    <ApplicationState>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MsalProvider instance={instance}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
          >
            <Box sx={{ display: "flex" }}>
              <NavBar
                authorizedMenuItems={authorizedMenuItems}
                isLoading={isLoading}
              />

              <BreadcrumbsBar />
              <Grid
                justifyContent="center"
                sx={{
                  marginTop: "131px",
                  width: "100%",
                }}
              >
                <RouterProvider router={router} />
              </Grid>
            </Box>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </LocalizationProvider>
    </ApplicationState>
  );
};

export default App;
