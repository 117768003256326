import { Button, Grid, Typography } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import { theme } from "../../Common/UI/styles/theme";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { config } from "../../authConfig";
import { CardContainer } from "../../Common/UI/components/CardContainer";
import { GridTableHeader } from "../../Common/UI/components/GridTableHeader";
import { GridTableRow } from "../../Common/UI/components/GridTableRow";
import { LoadingIndicator } from "../../Common/UI/components/Loading";
import "./PaytronixSyncErrors.css";

interface PaytronixSyncErrorData {
  operationId: string;
  timeStamp: string;
  message: string;
}


export default function PaytronixSyncErrors(): ReactElement {
  const execute = useFetchMsal();

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [data, setData] = useState<PaytronixSyncErrorData[] | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(1, "day"));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [dateError, setDateError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  function fetch() {
    if (endDate === null || startDate === null) return;

    setLoadingData(true);

    const url = config.rootAPIList.paytronixSyncErrors
      .replace("{startDate}", startDate?.format("YYYY-MM-DD"))
      .replace("{endDate}", endDate?.format("YYYY-MM-DD"));

    execute("GET", `${config.rootAPIUrl}${url}`)
      .then((result) => {
        console.log(result);

        setData(result);
        setLoadingData(false);
        setErrorMsg("");
      })
      .catch((error) => {
        setLoadingData(false);
        setErrorMsg(JSON.parse(error).message);
        console.log(error);
      })
      .finally(() => {});

    // setData([
    //     {
    //         "timeStamp": "2024-05-14T22:51:31.9654072+00:00",
    //         "operationId": "48ba5e52df162520d6b475612b459bb6",
    //         "category": "AddJobCode",
    //         "message": "Employee ID 7052014: Effective From Date should be greater than or equal to Hire Date"
    //     },
    //     {
    //         "timeStamp": "2024-05-14T22:51:24.1289389+00:00",
    //         "operationId": "a173b080259cab3d428ed6059097ef73",
    //         "category": "AddJobCode",
    //         "message": "Employee ID 7052022: Effective From Date should be greater than or equal to Hire Date"
    //     },
    //     {
    //         "timeStamp": "2024-05-14T22:50:32.3538814+00:00",
    //         "operationId": "ec37e6e2ac64735b5b4f4bd5ecb06de3",
    //         "category": "AddJobCode",
    //         "message": "Employee ID 7049182: An Error Occurred. Please check the input."
    //     },
    //     {
    //         "timeStamp": "2024-05-14T22:49:16.328847+00:00",
    //         "operationId": "6100a02ee0b7bfb0845bbebe8898e5c3",
    //         "category": "UpdateJobCode",
    //         "message": "Employee ID 7037390: Job Rate Assignment is not found for the employee"
    //     },
    //     {
    //         "timeStamp": "2024-05-14T02:47:22.5666716+00:00",
    //         "operationId": "61f60d1e64479488abbc533716a4b8d3",
    //         "category": "ModifyEmployeeNominalInfo",
    //         "message": "Employee ID 7040998: Primary Job Rate for location #121 Polaris is not active between 5/13/2024-5/14/2024 and 5/13/2024-5/14/2024. Please assign a primary job in that range."
    //     }
    // ]);
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <CardContainer
      title="Paytronix Sync Errors Report"
    >
      <GridTableHeader className="small-gaps">
        <Grid item xs={2}>
          <DatePicker
            disableFuture
            label={" Start Date "}
            onError={(newError) => {
              if (newError !== null) {
                setDateError(true);
              }
            }}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
              if (!!endDate && endDate.diff(newValue, "day") < 0) {
                setDateError(true);
                setErrorMsg("Start date must be before the end date!");
              } else {
                setDateError(false);
                setErrorMsg("");
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            disableFuture
            label={" End Date "}
            onError={(newError) => {
              if (newError !== null) {
                setDateError(true);
              }
            }}
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
              if (!!startDate && newValue!.diff(startDate, "day") < 0) {
                setDateError(true);
                setErrorMsg("Start date must be before the end date!");
              } else {
                setDateError(false);
                setErrorMsg("");
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={fetch} disabled={dateError}>
            Search
          </Button>
        </Grid>
      </GridTableHeader>
      <Typography className="error" color={theme.palette.error.main}>
        {errorMsg}
      </Typography>

      <GridTableHeader>
        <Grid item xs={3} className="cell">
          <Typography>Date</Typography>
        </Grid>
        <Grid item xs={8} className="cell">
          <Typography>Message</Typography>
        </Grid>
      </GridTableHeader>

      <LoadingIndicator show={loadingData}>
        {data?.map((log, i) => {
          return (
            <GridTableRow key={log.operationId + "_" + i}>
              <Grid item xs={3} className="cell">
                <Typography>
                  {dayjs(log.timeStamp).format("YYYY-MM-DD hh:mm:ss")}
                </Typography>
              </Grid>
              <Grid item xs={8} className="cell">
                <Typography>{log.message}</Typography>
              </Grid>
            </GridTableRow>
          );
        })}
      </LoadingIndicator>
    </CardContainer>
  );
}
