export default function AuthorizationHelp() {
  return (
    <>
      <p>Here you can manage access for all Custom Applications.</p>
      <ul>
        <li>Create Application button: Ability to Create a new custom application to manage access </li>
        <li>Use this page to Edit or Delete Existing Applications access </li>
        <li>
          For each Application:
          <ul>
            <li style={{ listStyleType: "circle" }}>Permissions: Create new role here that control set or menu or tabs</li>
            <li style={{ listStyleType: "circle" }}>
              Groups: Create access Group that manages group of roles. Also use this screen to assign users to access Groups
            </li>
            <li style={{ listStyleType: "circle" }}>
              Permissions Associations: Will show all users and with permissions that have within that application
            </li>
            <li style={{ listStyleType: "circle" }}>Reports: Export permission mapping for access review exercise</li>
          </ul>
        </li>
      </ul>
    </>
  );
}
