import { ReactNode, useEffect, useState } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../../authConfig";
import useIsAuthorized from "../hook/useIsAuthorized";
import React from "react";
import { LoadingIndicator } from "../components/Loading";
import useUserRoles from "../hook/useUserRoles";

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const RouteGuard = ({
  ...props
}: {
  roles: string[];
  children: ReactNode;
}) => {
  const { roles, isLoading } = useUserRoles();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  useEffect(() => {
    console.log("RouteGuard: useEffect " + props.roles.join(","));
    let intersection = props.roles.filter((role) => roles.includes(role));
    setIsAuthorized(intersection.length >= 0);
  }, [isLoading]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        ...loginRequest,
      }}
    >
      <LoadingIndicator show={isLoading}>
        {isAuthorized ? (
          <>{props.children}</>
        ) : (
          <div className="data-area-div">
            <h3>You are unauthorized to view this content.</h3>
          </div>
        )}
      </LoadingIndicator>
    </MsalAuthenticationTemplate>
  );
};
