import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import "./StoreInfo.css";
import { appRoles, config, pageNames } from "../../authConfig";
import { CardContainer } from "../../Common/UI/components/CardContainer";
import { GridTableHeader } from "../../Common/UI/components/GridTableHeader";
import { GridTableRow } from "../../Common/UI/components/GridTableRow";
import { LoadingIndicator } from "../../Common/UI/components/Loading";
import { CustomTabPanel } from "../../Common/UI/components/CustomTabPanel";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import useIsAuthorized from "../../Common/UI/hook/useIsAuthorized";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import { AudioVideo, StoreAggregate } from "./models/StoreAggregate";
import { GridRow } from "./components/GridRow";
import { useForm } from "../../Common/UI/hook/useForm";
import StoreWanEditForm from "./models/StoreWanEditForm";
import { useFormError } from "../../Common/UI/hook/useFormError";
import StoreWanEditFormError from "./models/StoreWanEditFormError";
import WANInfo from "./models/WanInfo";
import AVDialog from "./components/AVDialog";
import useUserRoles from "../../Common/UI/hook/useUserRoles";

function Store(): ReactElement {
  const initialFormData: StoreWanEditForm = {
    wanipAddress: "",
    physicalInterface: "",
    type: "",
    billing: "",
    isp: "",
    supportName: "",
    supportPhoneNumber: "",
    supportEmail: "",
    staticIP: "",
    subnet: "",
    gateway: "",
    notes: "",
  };
  const initialFormErrors: StoreWanEditFormError = {
    wanipAddress: "",
    physicalInterface: "",
    type: "",
    billing: "",
    isp: "",
    supportName: "",
    supportPhoneNumber: "",
    supportEmail: "",
    staticIP: "",
    subnet: "",
    gateway: "",
    notes: "",
  };

  const { storeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const execute = useFetchMsal();
  const { setState } = useContext(ApplicationLocationContext);

  const { formData, handleInputChange, handleMuiSelectChange, setFormData } =
    useForm<StoreWanEditForm>(initialFormData);

  const { formErrors, setFormErrors, isSubmittable, handleInputBlur } =
    useFormError<StoreWanEditFormError>(initialFormErrors);
  dayjs.extend(utc);

  //tabs
  const [value, setValue] = useState(0);
  const { roles } = useUserRoles();
  const showWan = useIsAuthorized([appRoles.StoreWANInfo], roles);
  const showPeople = useIsAuthorized([appRoles.StoreInfo.ViewPeople], roles);
  const showBuildingInfo = useIsAuthorized(
    [appRoles.StoreInfo.ViewPeople],
    roles
  );
  const showStoreHoursOfOpr = useIsAuthorized(
    [appRoles.StoreInfo.CardFreeHOO],
    roles
  );
  const showDevices = useIsAuthorized(
    [appRoles.StoreInfo.ViewPOSTerminals],
    roles
  );
  const showAV = useIsAuthorized([appRoles.StoreInfo.ViewAV], roles);

  //edit buttons
  const showEditWanButton = useIsAuthorized([appRoles.StoreWANEdit], roles);
  const showEditAVButtonRole = useIsAuthorized(
    [appRoles.StoreInfo.EditAV],
    roles
  );

  //selected store from dropdown
  const [selectedStore, setSelectedStore] = useState<StoreAggregate | null>();
  const [storesInfoAgg, setStoresInfoAgg] = useState<StoreAggregate[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  //edit wan
  const [showWanEditDialog, setShowWanEditDialog] = useState<boolean>(false);
  const [loadingWanUpdate, setLoadingWanUpdate] = useState<boolean>(false);
  const [editAddressButtonClicked, setEditAddressButtonClicked] = useState<
    string | null
  >(null);
  const [showEditAVDialog, setShowEditAVDialog] = useState<boolean>(false);

  const fetchStoresInfo = async () => {
    try {
      setLoading(true);
      const url = config.rootAPIList.store411Aggregate;
      const response = await execute("GET", `${config.rootAPIUrl}${url}`);

      for (let index = 0; index < response.length; index++) {
        if (response[index] && response[index].avEquipmentInventory === null) {
          response[index].avEquipmentInventory = {
            location: "",
            televisions: {
              total: 0,
              bar: 0,
              diningRoom: 0,
              patio: 0,
            },
            ampModel: "",
            zoneControllerModel: "",
            audioZones: 0,
            musicDevicePlayerId: "",
            atmosphereTvDeviceSerialNumber: "",
            tvProvider: {
              name: "Astound/Grande",
              accountNumber: "",
            },
          };
        }
      }

      setStoresInfoAgg(response);
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!location.state && !storesInfoAgg.length) {
      fetchStoresInfo();
    } else if (location.state) {
      if (location.state && location.state.avEquipmentInventory === null) {
        location.state.avEquipmentInventory = {
          location: "",
          televisions: {
            total: 0,
            bar: 0,
            diningRoom: 0,
            patio: 0,
          },
          ampModel: "",
          zoneControllerModel: "",
          audioZones: 0,
          musicDevicePlayerId: "",
          atmosphereTvDeviceSerialNumber: "",
          tvProvider: {
            name: "Astound/Grande",
            accountNumber: "",
          },
        };
      }
      setStoresInfoAgg(location.state as StoreAggregate[]);
    }
  }, [location.state, storesInfoAgg.length]);

  useEffect(() => {
    const selected = storesInfoAgg?.find(
      (x) => x.basicInfo?.number === storeId
    );
    setSelectedStore(selected);
  }, [storeId, storesInfoAgg]);

  useEffect(() => {
    setState({
      path: [pageNames.store411],
    });
  }, []);

  const storeEmail = useMemo(() => {
    return selectedStore !== null ? selectedStore?.email : "";
  }, [selectedStore]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (
      selectedStore?.basicInfo?.number === "806" ||
      selectedStore?.basicInfo?.number === "999"
    ) {
      setValue(0);
    } else {
      setValue(newValue);
    }
  };

  const handleEditButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event.currentTarget.name === "primaryWanButton") {
      setFormData(
        (selectedStore?.wanInfo?.find(
          (x: WANInfo) => x.type === "Primary"
        ) as StoreWanEditForm) ?? initialFormData
      );

      setEditAddressButtonClicked("Primary");
    } else if (event.currentTarget.name === "secondaryWanButton") {
      setFormData(
        (selectedStore?.wanInfo?.find(
          (x: WANInfo) => x.type === "Secondary"
        ) as StoreWanEditForm) ?? initialFormData
      );

      setEditAddressButtonClicked("Secondary");
    }

    setShowWanEditDialog(true);
  };

  const handleEditAVButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    //TODO
    // setFormData(
    //   (selectedStore?.wanInfo?.find(
    //     (x: WANInfo) => x.type === "Primary"
    //   ) as StoreWanEditForm) ?? initialFormData
    // );

    setShowEditAVDialog(true);
  };

  const handleClose = () => {
    setFormData(initialFormData);

    setFormErrors(initialFormErrors);

    setShowWanEditDialog(false);
  };

  const handleWanEdit = () => {
    const payload = {
      ...formData,
      location: selectedStore?.basicInfo?.number.padStart(4, "0") ?? "",
      id: selectedStore?.wanInfo?.find(
        (x: WANInfo) => x.type === editAddressButtonClicked
      )?.id,
    };

    const url = config.rootAPIList.store411NetworkEdit;

    setLoadingWanUpdate(true);

    execute("PUT", `${config.rootAPIUrl}${url}`, payload)
      .then(async (result) => {
        await fetchStoresInfo();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingWanUpdate(false);
        handleClose();
      });
  };

  const validateWanAddress = (ipAddress: string) => {
    const ipAddressPattern =
      /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (!ipAddressPattern.test(ipAddress)) {
      return "IP address isn't in the correct format";
    }

    return "";
  };

  const changeSelectedStoreUrlParam = (location: string, newParam: string) => {
    const pathParts = location.split("/");
    pathParts.pop();

    const newPath = `${pathParts.join("/")}/${newParam}`;

    navigate(newPath, { replace: true });
  };

  const validateInputLength = (value: string, maxLength: number) => {
    if (value.length > maxLength) {
      return `Cannot exceed ${maxLength} characters!`;
    }

    return "";
  };

  const normalizeInput = (value: string, previousValue: string) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  return (
    <>
      <Link to={"/store-411"} className="back-to-list-link">
        <ArrowBackIcon />
        Back to list
      </Link>
      <CardContainer title="Store 411" className="store-info">
        <GridTableHeader>
          <Grid item xs={6}>
            <LoadingIndicator show={loading}>
              <FormControl sx={{ width: "280px" }}>
                <InputLabel id="store-number-select">Select store</InputLabel>
                <Select
                  //location
                  labelId="store-number-select"
                  value={selectedStore?.basicInfo?.number || ""}
                  onChange={(ev) => {
                    if (!ev.target.value) return;

                    setSelectedStore(
                      storesInfoAgg?.find(
                        (x) => x.basicInfo?.number === ev.target.value
                      ) ?? null
                    );
                    changeSelectedStoreUrlParam(
                      location.pathname,
                      ev.target.value
                    );
                  }}
                >
                  {storesInfoAgg.map((x, i) => (
                    <MenuItem
                      key={`${i}-basic-info`}
                      value={x.basicInfo?.number}
                    >
                      {x.basicInfo?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </LoadingIndicator>
          </Grid>
        </GridTableHeader>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleTabChange} aria-label="">
              {selectedStore?.basicInfo?.number !== "806" &&
                selectedStore?.basicInfo?.number !== "999" && (
                  <Tab
                    label="Basic Info"
                    id={`simple-tab-1`}
                    aria-controls={`simple-tabpanel-1`}
                  />
                )}
              <Tab
                sx={showWan ? {} : { display: "none" }}
                label="Network"
                id={`simple-tab-2`}
                aria-controls={`simple-tabpanel-2`}
              />

              {selectedStore?.basicInfo?.number !== "806" &&
                selectedStore?.basicInfo?.number !== "999" && (
                  <Tab
                    sx={showPeople ? {} : { display: "none" }}
                    label="People"
                    id={`simple-tab-3`}
                    aria-controls={`simple-tabpanel-3`}
                  />
                )}
              {selectedStore?.basicInfo?.number !== "806" &&
                selectedStore?.basicInfo?.number !== "999" && (
                  <Tab
                    label="Building Properties"
                    id={`simple-tab-4`}
                    aria-controls={`simple-tabpanel-4`}
                  />
                )}
              {selectedStore?.basicInfo?.number !== "806" &&
                selectedStore?.basicInfo?.number !== "999" && (
                  <Tab
                    sx={showStoreHoursOfOpr ? {} : { display: "none" }}
                    label="CF HOO"
                    id={`simple-tab-5`}
                    aria-controls={`simple-tabpanel-5`}
                  />
                )}
              {selectedStore?.basicInfo?.number !== "806" &&
                selectedStore?.basicInfo?.number !== "999" && (
                  <Tab
                    sx={showDevices ? {} : { display: "none" }}
                    label="POS Terminals"
                    id={`simple-tab-6`}
                    aria-controls={`simple-tabpanel-6`}
                  />
                )}
              {selectedStore?.basicInfo?.number !== "806" &&
                selectedStore?.basicInfo?.number !== "999" && (
                  <Tab
                    sx={showAV ? {} : { display: "none" }}
                    label="AV"
                    id={`simple-tab-7`}
                    aria-controls={`simple-tabpanel-7`}
                  />
                )}
            </Tabs>
          </Box>
          {/* panel 1 */}
          {selectedStore?.basicInfo?.number !== "806" &&
            selectedStore?.basicInfo?.number !== "999" && (
              <CustomTabPanel value={value} index={0}>
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Status</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.name !== ""
                        ? selectedStore?.basicInfo?.status
                        : ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                {/* 2 */}
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Number</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.number ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                {/* 3 */}
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Name</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.name ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>

                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Alternate Name</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.alternateName ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Email</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>{storeEmail}</Typography>
                  </Grid>
                </GridTableRow>
                {/* 4 */}
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Open date</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.openDate
                        ? dayjs(selectedStore?.basicInfo?.openDate).format(
                            "MM-DD-YYYY"
                          )
                        : ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                {/* 5 */}

                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Address Line 1</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.addressLine1 ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                {/* 6 */}
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Address Line 2</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.addressLine2 ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                {/* 7 */}
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store City</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.city ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                {/* 8 */}
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store State</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.state ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                {/* 9 */}
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Zip Code</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.postalCode ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                {/* 10 */}
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Phone</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.phone ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Direct Phone Number</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.directPhoneNumber ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                {/* 11 */}
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Region</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.region ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                {/* 12 */}
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store District</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.district ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>

                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Date closed</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.dateClosed ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>

                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Time Zone</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.basicInfo?.timeZone ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
              </CustomTabPanel>
            )}

          {/* panel 2 */}
          {showWan ? (
            <CustomTabPanel
              value={value}
              index={
                selectedStore?.basicInfo?.number === "806" ||
                selectedStore?.basicInfo?.number === "999"
                  ? 0
                  : 1
              }
            >
              <LoadingIndicator show={loading}>
                <Typography sx={{ mb: 2 }} variant="h6" component={"h6"}>
                  Connection One
                </Typography>
                {showEditWanButton && selectedStore?.basicInfo?.number ? (
                  <Button
                    sx={{ mb: 1 }}
                    onClick={(e) => handleEditButtonClick(e)}
                    name="primaryWanButton"
                  >
                    Edit
                  </Button>
                ) : (
                  <></>
                )}
                <GridRow
                  {...{
                    field: "IP Address",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Primary"
                    )?.wanipAddress,
                  }}
                />
                <GridRow
                  {...{
                    field: "Physical Interface",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Primary"
                    )?.physicalInterface,
                  }}
                />
                <GridRow
                  {...{
                    field: "Type",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Primary"
                    )?.type,
                  }}
                />
                <GridRow
                  {...{
                    field: "Billing",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Primary"
                    )?.billing,
                  }}
                />
                <GridRow
                  {...{
                    field: "ISP",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Primary"
                    )?.isp,
                  }}
                />
                <GridRow
                  {...{
                    field: "Support Name",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Primary"
                    )?.supportName,
                  }}
                />
                <GridRow
                  {...{
                    field: "Support Phone Number",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Primary"
                    )?.supportPhoneNumber,
                  }}
                />
                <GridRow
                  {...{
                    field: "Support Email",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Primary"
                    )?.supportEmail,
                  }}
                />
                <GridRow
                  {...{
                    field: "Static IP",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Primary"
                    )?.staticIP,
                  }}
                />
                <GridRow
                  {...{
                    field: "Subnet",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Primary"
                    )?.subnet,
                  }}
                />
                <GridRow
                  {...{
                    field: "Gateway",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Primary"
                    )?.gateway,
                  }}
                />
                <GridRow
                  {...{
                    field: "Notes",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Primary"
                    )?.notes,
                  }}
                />
                <Typography sx={{ mb: 2, mt: 3 }} variant="h6" component={"h6"}>
                  Connection Two
                </Typography>
                {showEditWanButton && selectedStore?.basicInfo?.number ? (
                  <Button
                    sx={{ mb: 1 }}
                    onClick={(e) => handleEditButtonClick(e)}
                    name="secondaryWanButton"
                  >
                    Edit
                  </Button>
                ) : (
                  <></>
                )}
                <GridRow
                  {...{
                    field: "IP Address",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Secondary"
                    )?.wanipAddress,
                  }}
                />
                <GridRow
                  {...{
                    field: "Physical Interface",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Secondary"
                    )?.physicalInterface,
                  }}
                />
                <GridRow
                  {...{
                    field: "Type",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Secondary"
                    )?.type,
                  }}
                />
                <GridRow
                  {...{
                    field: "Billing",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Secondary"
                    )?.billing,
                  }}
                />
                <GridRow
                  {...{
                    field: "ISP",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Secondary"
                    )?.isp,
                  }}
                />
                <GridRow
                  {...{
                    field: "Support Name",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Secondary"
                    )?.supportName,
                  }}
                />
                <GridRow
                  {...{
                    field: "Support Phone Number",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Secondary"
                    )?.supportPhoneNumber,
                  }}
                />
                <GridRow
                  {...{
                    field: "Support Email",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Secondary"
                    )?.supportEmail,
                  }}
                />
                <GridRow
                  {...{
                    field: "Static IP",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Secondary"
                    )?.staticIP,
                  }}
                />
                <GridRow
                  {...{
                    field: "Subnet",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Secondary"
                    )?.subnet,
                  }}
                />
                <GridRow
                  {...{
                    field: "Gateway",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Secondary"
                    )?.gateway,
                  }}
                />
                <GridRow
                  {...{
                    field: "Notes",
                    data: selectedStore?.wanInfo?.find(
                      (x: WANInfo) => x.type === "Secondary"
                    )?.notes,
                  }}
                />
              </LoadingIndicator>
              <Dialog
                open={showWanEditDialog}
                onClose={handleClose}
                aria-hidden={!showWanEditDialog}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <DialogTitle>
                    Edit Connection{" "}
                    {editAddressButtonClicked === "Primary" ? "One" : "Two"}
                  </DialogTitle>
                  <IconButton
                    size="small"
                    disableRipple
                    sx={{
                      "&:hover": {
                        border: "1px solid black",
                        borderRadius: "3px",
                      },
                    }}
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <LoadingIndicator show={loadingWanUpdate}>
                  <DialogContent>
                    <Box className="card-container">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "16px",
                        }}
                      >
                        <TextField
                          required
                          label="IP Address"
                          name="wanipAddress"
                          error={formErrors?.wanipAddress !== ""}
                          helperText={formErrors?.wanipAddress}
                          value={formData.wanipAddress}
                          onChange={handleInputChange}
                          onBlur={(e) => handleInputBlur(e, validateWanAddress)}
                        />
                        <TextField
                          label="Physical Interface"
                          name="physicalInterface"
                          error={formErrors?.physicalInterface !== ""}
                          helperText={formErrors?.physicalInterface}
                          value={formData.physicalInterface}
                          onChange={handleInputChange}
                          onBlur={(e) =>
                            handleInputBlur(e, validateInputLength, 50)
                          }
                        />
                        <FormControl fullWidth>
                          <InputLabel id="type-label">Type</InputLabel>
                          <Select
                            labelId="type-label"
                            label="Type"
                            name="type"
                            value={formData.type ?? ""}
                            onChange={handleMuiSelectChange}
                          >
                            <MenuItem value={"Primary"}>Primary</MenuItem>
                            <MenuItem value={"Secondary"}>Secondary</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          label="Billing"
                          name="billing"
                          error={formErrors?.billing !== ""}
                          helperText={formErrors?.billing}
                          value={formData.billing}
                          onChange={handleInputChange}
                          onBlur={(e) =>
                            handleInputBlur(e, validateInputLength, 50)
                          }
                        />
                        <TextField
                          label="ISP"
                          name="isp"
                          error={formErrors?.isp !== ""}
                          helperText={formErrors?.isp}
                          value={formData.isp}
                          onChange={handleInputChange}
                          onBlur={(e) =>
                            handleInputBlur(e, validateInputLength, 50)
                          }
                        />
                        <TextField
                          label="Support Name"
                          name="supportName"
                          error={formErrors?.supportName !== ""}
                          helperText={formErrors?.supportName}
                          value={formData.supportName}
                          onChange={handleInputChange}
                          onBlur={(e) =>
                            handleInputBlur(e, validateInputLength, 50)
                          }
                        />
                        <TextField
                          label="Support Phone Number"
                          name="supportPhoneNumber"
                          error={formErrors?.supportPhoneNumber !== ""}
                          helperText={formErrors?.supportPhoneNumber}
                          value={formData.supportPhoneNumber}
                          placeholder="(xxx) xxx-xxxx"
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              [e.target.name]: normalizeInput(
                                e.target.value,
                                prev.supportPhoneNumber
                              ),
                            }))
                          }
                          onBlur={(e) =>
                            handleInputBlur(e, (input) => {
                              const usaPhoneNumberPattern =
                                /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;

                              if (!usaPhoneNumberPattern.test(input)) {
                                return "Incorrect phone number format! Correct format: (NNN) NNN-NNNN";
                              }

                              return "";
                            })
                          }
                        />
                        <TextField
                          label="Support Email"
                          name="supportEmail"
                          error={formErrors?.supportEmail !== ""}
                          helperText={formErrors?.supportEmail}
                          value={formData.supportEmail}
                          onChange={handleInputChange}
                          onBlur={(e) =>
                            handleInputBlur(e, (input) => {
                              const emailPattern =
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                              if (input.length > 50) {
                                return "Cannot exceed 50 characters";
                              } else if (!emailPattern.test(input)) {
                                return "Incorrect email format";
                              }

                              return "";
                            })
                          }
                        />
                        <TextField
                          label="Static IP"
                          name="staticIP"
                          error={formErrors?.staticIP !== ""}
                          helperText={formErrors?.staticIP}
                          value={formData.staticIP}
                          onChange={handleInputChange}
                          onBlur={(e) => handleInputBlur(e, validateWanAddress)}
                        />
                        <TextField
                          label="Subnet"
                          name="subnet"
                          error={formErrors?.subnet !== ""}
                          helperText={formErrors?.subnet}
                          value={formData.subnet}
                          onChange={handleInputChange}
                          onBlur={(e) => handleInputBlur(e, validateWanAddress)}
                        />
                        <TextField
                          label="Gateway"
                          name="gateway"
                          error={formErrors?.gateway !== ""}
                          helperText={formErrors?.gateway}
                          value={formData.gateway}
                          onChange={handleInputChange}
                          onBlur={(e) => handleInputBlur(e, validateWanAddress)}
                        />
                        <FormControl>
                          <label className="notes-field-label">Notes</label>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              border: "1px solid #D2D1D3",
                              borderRadius: "10px",
                            }}
                          >
                            <TextareaAutosize
                              placeholder="Notes"
                              name="notes"
                              value={formData.notes}
                              minRows={5}
                              maxRows={20}
                              onChange={handleInputChange}
                              className="notes-field"
                              onBlur={(e) =>
                                handleInputBlur(e, validateInputLength, 200)
                              }
                            />
                            <Typography
                              variant="body1"
                              component={"p"}
                              sx={{
                                px: "14px",
                                pt: formErrors.notes === "" ? "0px" : "3px",
                                lineHeight: 1.66,
                                fontSize: "0.75rem",
                                color:
                                  formErrors.notes === ""
                                    ? "rgba(0, 0, 0, 0.6)"
                                    : "error.main",
                              }}
                            >
                              {formErrors.notes}
                            </Typography>
                          </Box>
                        </FormControl>
                      </Box>
                    </Box>
                  </DialogContent>
                </LoadingIndicator>
                <DialogActions>
                  {/* TODO: Change color of cancel button to black (secondary color) */}
                  <Button
                    sx={{ bgcolor: "secondary.main" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {!loadingWanUpdate ? (
                    <Button disabled={!isSubmittable} onClick={handleWanEdit}>
                      Save
                    </Button>
                  ) : (
                    <></>
                  )}
                </DialogActions>
              </Dialog>
            </CustomTabPanel>
          ) : (
            <></>
          )}

          {/* panel 3 */}
          {showPeople &&
          selectedStore?.basicInfo?.number !== "806" &&
          selectedStore?.basicInfo?.number !== "999" ? (
            <CustomTabPanel value={value} index={2}>
              <LoadingIndicator show={loading}>
                <GridTableHeader>
                  <Grid item xs={2} className="cell">
                    <Typography>Name</Typography>
                  </Grid>
                  <Grid item xs={1} className="cell">
                    <Typography>Code</Typography>
                  </Grid>
                  <Grid item xs={3} className="cell">
                    <Typography>Title</Typography>
                  </Grid>
                  <Grid item xs={4} className="cell">
                    <Typography>Email</Typography>
                  </Grid>
                  <Grid item xs={2} className="cell">
                    <Typography>Phone number</Typography>
                  </Grid>
                </GridTableHeader>
                {selectedStore?.managers?.map((x) => {
                  return (
                    <GridTableRow
                      key={
                        selectedStore.basicInfo?.number +
                        x.employeeNumber +
                        "-people-tab"
                      }
                    >
                      <Grid item xs={2} className="cell">
                        <Typography>
                          {!x.firstName || !x.lastName
                            ? ""
                            : x.firstName + " " + x.lastName}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} className="cell">
                        <Typography>{x.jobCodeNumber ?? ""}</Typography>
                      </Grid>
                      <Grid item xs={3} className="cell">
                        <Typography>{x.jobName ?? ""}</Typography>
                      </Grid>
                      <Grid item xs={4} className="cell">
                        <Typography>{x.email ?? ""}</Typography>
                      </Grid>
                      <Grid item xs={2} className="cell">
                        <Typography>{x.phoneNumber ?? ""}</Typography>
                      </Grid>
                    </GridTableRow>
                  );
                })}
              </LoadingIndicator>
            </CustomTabPanel>
          ) : (
            <></>
          )}

          {/* panel 4 */}
          {showBuildingInfo &&
          selectedStore?.basicInfo?.number !== "806" &&
          selectedStore?.basicInfo?.number !== "999" ? (
            <CustomTabPanel value={value} index={3}>
              <LoadingIndicator show={loading}>
                {/* 1 */}
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store SQFT</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.building?.squareFeet ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>

                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Store Gen</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.building?.generation ?? ""}
                    </Typography>
                  </Grid>
                </GridTableRow>
                {/*<GridTableRow>*/}
                {/*    <Grid item xs={5}>*/}
                {/*        <Typography>Drive Through</Typography>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={7}>*/}
                {/*        <Switch*/}
                {/*            disabled*/}
                {/*            {...{ inputProps: { "aria-label": "Drive Through" } }}*/}
                {/*            checked={!!selectedStore?.cardFreeInfo?.driveThrough}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*</GridTableRow>*/}
                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography>Pickup window</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Switch
                      disabled
                      {...{ inputProps: { "aria-label": "Pickup window" } }}
                      checked={
                        !!selectedStore?.cardFreeInfo?.driveThroughPickup
                      }
                    />
                  </Grid>
                </GridTableRow>
              </LoadingIndicator>
            </CustomTabPanel>
          ) : (
            <></>
          )}
          {/* panel 5 */}

          {showStoreHoursOfOpr &&
          selectedStore?.basicInfo?.number !== "806" &&
          selectedStore?.basicInfo?.number !== "999" ? (
            <CustomTabPanel value={value} index={4}>
              <LoadingIndicator show={loading}>
                <GridTableRow>
                  <Grid item xs={3}>
                    <Typography>Day</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>Open</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>Close</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>TimeZone</Typography>
                  </Grid>
                </GridTableRow>

                <GridTableRow>
                  <Grid item xs={3}>
                    <Typography>Sunday</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.sunday?.open
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.sunday?.close
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {selectedStore?.hoursOfOperation?.timeZone}
                    </Typography>
                  </Grid>
                </GridTableRow>
                <GridTableRow>
                  <Grid item xs={3}>
                    <Typography>Monday</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.monday?.open
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.monday?.close
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {selectedStore?.hoursOfOperation?.timeZone}
                    </Typography>
                  </Grid>
                </GridTableRow>
                <GridTableRow>
                  <Grid item xs={3}>
                    <Typography>Tuesday</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.tuesday?.open
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.tuesday?.close
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {selectedStore?.hoursOfOperation?.timeZone}
                    </Typography>
                  </Grid>
                </GridTableRow>
                <GridTableRow>
                  <Grid item xs={3}>
                    <Typography>Wednesday</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.wednesday?.open
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.wednesday?.close
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {selectedStore?.hoursOfOperation?.timeZone}
                    </Typography>
                  </Grid>
                </GridTableRow>
                <GridTableRow>
                  <Grid item xs={3}>
                    <Typography>Thursday</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.thursday?.open
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.thursday?.close
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {selectedStore?.hoursOfOperation?.timeZone}
                    </Typography>
                  </Grid>
                </GridTableRow>
                <GridTableRow>
                  <Grid item xs={3}>
                    <Typography>Friday</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.friday?.open
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.friday?.close
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {selectedStore?.hoursOfOperation?.timeZone}
                    </Typography>
                  </Grid>
                </GridTableRow>
                <GridTableRow>
                  <Grid item xs={3}>
                    <Typography>Saturday</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.saturday?.open
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {
                        selectedStore?.hoursOfOperation?.dailyOperatingSchedules
                          ?.saturday?.close
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {selectedStore?.hoursOfOperation?.timeZone}
                    </Typography>
                  </Grid>
                </GridTableRow>
              </LoadingIndicator>
            </CustomTabPanel>
          ) : (
            <></>
          )}

          {showDevices &&
          selectedStore?.basicInfo?.number !== "806" &&
          selectedStore?.basicInfo?.number !== "999" ? (
            <CustomTabPanel value={value} index={5}>
              <LoadingIndicator show={loading}>
                <GridTableRow>
                  <Grid item xs={3}>
                    <Typography>Terminal Name</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>Status</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>Last access timestamp</Typography>
                  </Grid>
                </GridTableRow>
                {selectedStore?.devices?.map((terminal) => {
                  return (
                    <GridTableRow key={terminal.name + terminal.location}>
                      <Grid item xs={3}>
                        <Typography>{terminal.name}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {terminal.status.charAt(0).toUpperCase() +
                            terminal.status.slice(1)}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>
                          {dayjs
                            .utc(terminal.lastUpdated)
                            .local()
                            .format("MM/DD/YYYY hh:mm A")}
                        </Typography>
                      </Grid>
                    </GridTableRow>
                  );
                })}
              </LoadingIndicator>
            </CustomTabPanel>
          ) : (
            <></>
          )}

          {showAV &&
          selectedStore?.basicInfo?.number !== "806" &&
          selectedStore?.basicInfo?.number !== "999" ? (
            <CustomTabPanel value={value} index={6}>
              <LoadingIndicator show={loading}>
                {showEditAVButtonRole && selectedStore?.basicInfo?.number ? (
                  <Button sx={{ mb: 1 }} onClick={handleEditAVButtonClick}>
                    Edit
                  </Button>
                ) : (
                  <></>
                )}

                <GridRow
                  {...{
                    field: "# Total TVs",
                    data:
                      selectedStore?.avEquipmentInventory?.televisions.total +
                      "",
                  }}
                />

                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography sx={{ paddingLeft: "40px" }}>
                      {"# Bar TVs"}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.avEquipmentInventory?.televisions.bar}
                    </Typography>
                  </Grid>
                </GridTableRow>

                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography paddingLeft={"40px"}>
                      {"# Dining Room TVs"}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {
                        selectedStore?.avEquipmentInventory?.televisions
                          .diningRoom
                      }
                    </Typography>
                  </Grid>
                </GridTableRow>

                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography paddingLeft={"40px"}>
                      {"# Patio TVs"}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {selectedStore?.avEquipmentInventory?.televisions.patio}
                    </Typography>
                  </Grid>
                </GridTableRow>

                <GridRow
                  {...{
                    field: "Amp Model",
                    data: selectedStore?.avEquipmentInventory?.ampModel,
                  }}
                />
                <GridRow
                  {...{
                    field: "Zone Controller Model",
                    data: selectedStore?.avEquipmentInventory
                      ?.zoneControllerModel,
                  }}
                />
                <GridRow
                  {...{
                    field: "# of Audio Zones",
                    data: selectedStore?.avEquipmentInventory?.audioZones + "",
                  }}
                />
                <GridRow
                  {...{
                    field: "Music Device Player ID",
                    data:
                      selectedStore?.avEquipmentInventory?.musicDevicePlayerId +
                      "",
                  }}
                />
                <GridRow
                  {...{
                    field: "Atmosphere TV Device Serial #",
                    data:
                      selectedStore?.avEquipmentInventory
                        ?.atmosphereTvDeviceSerialNumber + "",
                  }}
                />
                <GridRow
                  {...{
                    field: "TV Provider",
                    data:
                      selectedStore?.avEquipmentInventory?.tvProvider.name + "",
                  }}
                />

                <GridTableRow>
                  <Grid item xs={5}>
                    <Typography sx={{ paddingLeft: "40px" }}>
                      {"TV Provider Account #"}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {
                        selectedStore?.avEquipmentInventory?.tvProvider
                          .accountNumber
                      }
                    </Typography>
                  </Grid>
                </GridTableRow>
              </LoadingIndicator>
              {showEditAVDialog ? (
                <AVDialog
                  show={showEditAVDialog}
                  setShow={setShowEditAVDialog}
                  initialFormData={
                    selectedStore?.avEquipmentInventory as AudioVideo
                  }
                  location={
                    selectedStore?.basicInfo?.number.padStart(4, "0") ?? ""
                  }
                  fetchStoresInfo={fetchStoresInfo}
                />
              ) : (
                <></>
              )}
            </CustomTabPanel>
          ) : (
            <></>
          )}
        </Box>
      </CardContainer>
    </>
  );
}
export default Store;
