import { Box, Button, Grid, Typography } from "@mui/material";
import React, { ReactElement, SetStateAction, useEffect, useState } from "react";
import { CustomTabPanel } from "../../../Common/UI/components/CustomTabPanel";
import { GridTableRow } from "../../../Common/UI/components/GridTableRow";
import { GridTableHeader } from "../../../Common/UI/components/GridTableHeader";
import { GroupDto, PermissionDto } from "../Interfaces";
import { config } from "../../../authConfig";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import { LoadingIndicator } from "../../../Common/UI/components/Loading";
import DeleteConfirmation from "../DeleteConfirmation";
import EditGroup from "./EditGroup";
import CreateGroup from "./CreateGroup";
import ManageUsers from "./ManageUsers";

export default function GroupsTab({
  value,
  index,
  applicationName,
  setRefreshTrigger
}: {
  value: number;
  index: number;
  applicationName: string;
  setRefreshTrigger: React.Dispatch<SetStateAction<number>>;
}): ReactElement {
  const execute = useFetchMsal();
  const [data, setData] = useState<GroupDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const url = config.rootAPIList.groups.replace(
        "{applicationName}",
        applicationName
      );

      setData(await execute("GET", `${config.rootAPIUrl}${url}`));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteHandler = async (item: {name:string}) => {
    try {
      setIsLoading(true);
      const url = config.rootAPIList.deleteGroup
        .replace("{applicationName}", applicationName)
        .replace("{groupName}", item.name);

      await execute("DELETE", `${config.rootAPIUrl}${url}`);

      setRefreshTrigger((prev) => prev + 1);
      fetchData();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingIndicator show={isLoading}>
      <CustomTabPanel value={value} index={index}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            paddingBottom: "20px",
          }}
        >
          <CreateGroup appName={applicationName} refresh={()=>{ fetchData(); setRefreshTrigger((prev) => prev + 1);}} />
        </Box>
        <GridTableHeader>
          <Grid item xs={3} sx={{ fontWeight: "600", fontSize: "18px" }}>
            Name
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ fontWeight: "600", fontSize: "18px", paddingLeft: "10px" }}
          >
            Description
          </Grid>
          <Grid
            item
            xs={5}

            sx={{ fontWeight: "600", fontSize: "18px", paddingLeft: "10px", textAlign:'right' }}
          >
            Actions
          </Grid>
        </GridTableHeader>
        {data.map((x) => {
          return (
            <GridTableRow key={"row" + x.name}>
              <Grid item xs={3}>
                <Typography>{x.name}</Typography>
              </Grid>
              <Grid item xs={4} sx={{ paddingLeft: "10px" }}>
                <Typography>{x.description}</Typography>
              </Grid>
              <Grid item xs={5}
                sx={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "right",
                  gap: "10px",
                }}
              >
                <EditGroup
                  appName={applicationName}
                  descriptionName={x.description}
                  itemName={x.name}
                  refresh={fetchData}
                />
                <ManageUsers
                  appName={applicationName}
                  groupName={x.name}
                  refresh={fetchData}
                />
                 <DeleteConfirmation
                  deleteHandler={deleteHandler}
                  message={`Are you sure you want to delete group ${x.name}`}
                  item={x}
                />
              </Grid>
            </GridTableRow>
          );
        })}
      </CustomTabPanel>
    </LoadingIndicator>
  );
}
