import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  TextField,
  Button,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { config } from "../../../authConfig";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import { PermissionDto } from "../Interfaces";
import { LoadingIndicator } from "../../../Common/UI/components/Loading";

export default function UserPermission({
  applicationName,
  permissionsAvailable,
  refresh,
  handleClose,
}: {
  applicationName: string;
  permissionsAvailable: PermissionDto[];
  refresh: () => void;
  handleClose: () => void;
}) {
  const execute = useFetchMsal();
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [permissionSelected, setPermissionSelected] = useState<string>("");

  function assignPermissionHandler() {
    setIsLoading(true);

    var payload = {
      applicationName: applicationName,
      permissionName: permissionSelected,
      email: email,
    };

    var url = config.rootAPIList.assignPermissionToUser
      .replace("{applicationName}", applicationName)
      .replace("{email}", email);

    execute("POST", `${config.rootAPIUrl}${url}`, payload)
      .then(() => {
        setIsLoading(false);
        refresh();
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        handleClose();
      });
  }

  return (
    <LoadingIndicator show={isLoading}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "start",
        }}
      >
        <Grid item xs={6}>
          <Box sx={{ paddingRight: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="perm-label">Permission</InputLabel>
              <Select
                labelId="perm-label"
                id="perm-type"
                value={permissionSelected}
                label="Permission"
                onChange={(event: SelectChangeEvent) => {
                  setPermissionSelected(event.target.value);
                }}
              >
                {permissionsAvailable.map((x: PermissionDto) => (
                  <MenuItem key={"select-" + x.name} value={x.name}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ paddingLeft: "10px" }}>
            {" "}
            <TextField
              fullWidth
              required
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "right", paddingTop: "20px" }}>
          <Button
            disabled={isLoading || !permissionSelected || email.length === 0}
            onClick={assignPermissionHandler}
          >
            Assign
          </Button>
        </Grid>
      </Grid>
    </LoadingIndicator>
  );
}
