import { RouteObject } from "react-router-dom";
import ErrorBoundary from "../Common/UI/utils/ErrorBoundary";
import { RouteGuard } from "../Common/UI/utils/RouteGuard";
import MetaNavbarRoutes, { NavbarRoutes } from "./MetaNavbarRoutes";

interface MainCtrl {
  getAuthorizedMenuItems: (roles: string[]) => NavbarRoutes[];
  reactNavigationRoutes: RouteObject[];
}

export const MainController = function (): MainCtrl {
  const routesMeta: NavbarRoutes[] = MetaNavbarRoutes.getRoutes();
  const reactNavigationRoutes: RouteObject[] = [];

  const registerRoutes = function (route: NavbarRoutes) {
      reactNavigationRoutes.push({
        path: ("/" + route.routerPath) as string,
        element: (
          <RouteGuard roles={route.roles}>
            <ErrorBoundary>{route.control}</ErrorBoundary>
          </RouteGuard>
        ),
      });

    route.children?.map((x: any) => registerRoutes(x));
  };
  routesMeta.map(route=>registerRoutes(route));

  const filterAuthorizedMenuItems = function(routesCofig: NavbarRoutes[] | null, roles: string[]) :NavbarRoutes[] {

    if (routesCofig === null || routesCofig.length === 0) {
      return [];
    }

    var menuItems_filtered = routesCofig.filter(
      (item) =>
        // item.isActivePage &&
        (item.roles.some((element) => roles?.includes(element)) ||
          item.roles.length === 0)
    );

    menuItems_filtered.map((x) => {
      x.children = filterAuthorizedMenuItems(x.children, roles);
    });

    return menuItems_filtered;
  }

  const getAuthorizedMenuItems = function (roles: string[]){
    var items = filterAuthorizedMenuItems(routesMeta, roles);
    items = items.filter((item) => {
      return (
        item.isMenuItem === true ||
        (item.isMenuItem === false &&
          item.children !== null &&
          item.children?.length > 0)
      );
    });

    return items;
  }

  return {
    getAuthorizedMenuItems,
    reactNavigationRoutes,
  };
};
