import { Button, Divider, Grid, Typography } from "@mui/material";
import { ReactElement, useContext, useEffect, useState } from "react";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import { theme } from "../../Common/UI/styles/theme";
import "./EmployeeSyncErrors.css";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { appRoles, config } from "../../authConfig";
import { CardContainer } from "../../Common/UI/components/CardContainer";
import { GridTableHeader } from "../../Common/UI/components/GridTableHeader";
import { GridTableRow } from "../../Common/UI/components/GridTableRow";
import { LoadingIndicator } from "../../Common/UI/components/Loading";
import "./EmployeeSyncErrors.css";
import React from "react";

interface EmployeeSyncErrors {
  timeStamp: string;
  operationId: string;
  category: string;
  message: string;
}

enum CategoryEmplSyncErrorLog {
  AddJobCode,
  AssignAwayStoreToEmployee,
  CreateEmployee,
  ModifyEmployeeNominalInfo,
  ModifyEmployeePosConfig,
  RehireEmployee,
  TerminateEmployee,
  TransferEmployee,
  UpdateJobCode,
}

export default function EmployeeSyncErrors(): ReactElement {
  const execute = useFetchMsal();

  const [loadingEmployeeSyncErrors, setLoadingEmployeeSyncErrors] =
    useState<boolean>(false);
  const [employeeSyncData, setEmployeeSyncData] = useState<
    EmployeeSyncErrors[] | null
  >(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().subtract(1, "day")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [dateError, setDateError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  function getEmplSyncErrors() {
    if (endDate === null || startDate === null) return;

    setLoadingEmployeeSyncErrors(true);

    const url = config.rootAPIList.employeeSyncErrors
      .replace("{startDate}", startDate?.format("YYYY-MM-DD"))
      .replace("{endDate}", endDate?.format("YYYY-MM-DD"));

    execute("GET", `${config.rootAPIUrl}${url}`)
      .then((result) => {
        console.log(result);

        setEmployeeSyncData(result);
        setLoadingEmployeeSyncErrors(false);
        setErrorMsg("");
      })
      .catch((error) => {
        setLoadingEmployeeSyncErrors(false);
        setErrorMsg(JSON.parse(error).message);
        console.log(error);
      })
      .finally(() => {});

    // setEmployeeSyncData([
    //     {
    //         "timeStamp": "2024-05-14T22:51:31.9654072+00:00",
    //         "operationId": "48ba5e52df162520d6b475612b459bb6",
    //         "category": "AddJobCode",
    //         "message": "Employee ID 7052014: Effective From Date should be greater than or equal to Hire Date"
    //     },
    //     {
    //         "timeStamp": "2024-05-14T22:51:24.1289389+00:00",
    //         "operationId": "a173b080259cab3d428ed6059097ef73",
    //         "category": "AddJobCode",
    //         "message": "Employee ID 7052022: Effective From Date should be greater than or equal to Hire Date"
    //     },
    //     {
    //         "timeStamp": "2024-05-14T22:50:32.3538814+00:00",
    //         "operationId": "ec37e6e2ac64735b5b4f4bd5ecb06de3",
    //         "category": "AddJobCode",
    //         "message": "Employee ID 7049182: An Error Occurred. Please check the input."
    //     },
    //     {
    //         "timeStamp": "2024-05-14T22:49:16.328847+00:00",
    //         "operationId": "6100a02ee0b7bfb0845bbebe8898e5c3",
    //         "category": "UpdateJobCode",
    //         "message": "Employee ID 7037390: Job Rate Assignment is not found for the employee"
    //     },
    //     {
    //         "timeStamp": "2024-05-14T02:47:22.5666716+00:00",
    //         "operationId": "61f60d1e64479488abbc533716a4b8d3",
    //         "category": "ModifyEmployeeNominalInfo",
    //         "message": "Employee ID 7040998: Primary Job Rate for location #121 Polaris is not active between 5/13/2024-5/14/2024 and 5/13/2024-5/14/2024. Please assign a primary job in that range."
    //     }
    // ]);
  }

  useEffect(() => {
    getEmplSyncErrors();
  }, []);

  function categoryMapper(name: string): string {
    const category: CategoryEmplSyncErrorLog =
      CategoryEmplSyncErrorLog[name as keyof typeof CategoryEmplSyncErrorLog];
    let result = "";
    switch (category) {
      case CategoryEmplSyncErrorLog.AddJobCode:
        result = "Add Job Code";
        break;
      case CategoryEmplSyncErrorLog.AssignAwayStoreToEmployee:
        result = "Assign Away Store To Employee";
        break;
      case CategoryEmplSyncErrorLog.CreateEmployee:
        result = "Create Employee";
        break;
      case CategoryEmplSyncErrorLog.ModifyEmployeeNominalInfo:
        result = "Modify Employee Nominal Info";
        break;
      case CategoryEmplSyncErrorLog.ModifyEmployeePosConfig:
        result = "Modify Employee Pos Config";
        break;
      case CategoryEmplSyncErrorLog.RehireEmployee:
        result = "Rehire Employee";
        break;
      case CategoryEmplSyncErrorLog.TerminateEmployee:
        result = "Terminate Employee";
        break;
      case CategoryEmplSyncErrorLog.TransferEmployee:
        result = "Transfer Employee";
        break;
      case CategoryEmplSyncErrorLog.UpdateJobCode:
        result = "Update Job Code";
        break;
      default:
        break;
    }

    return result;
  }

  return (
    <CardContainer
      title="Employee Sync Errors Report"
      className="empl-sync-errors"
    >
      <GridTableHeader className="small-gaps">
        <Grid item xs={2}>
          <DatePicker
            disableFuture
            label={" Start Date "}
            onError={(newError) => {
              if (newError !== null) {
                setDateError(true);
              }
            }}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
              if (!!endDate && endDate.diff(newValue, "day") < 0) {
                setDateError(true);
                setErrorMsg("Start date must be before the end date!");
              } else {
                setDateError(false);
                setErrorMsg("");
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            disableFuture
            label={" End Date "}
            onError={(newError) => {
              if (newError !== null) {
                setDateError(true);
              }
            }}
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
              if (!!startDate && newValue!.diff(startDate, "day") < 0) {
                setDateError(true);
                setErrorMsg("Start date must be before the end date!");
              } else {
                setDateError(false);
                setErrorMsg("");
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={getEmplSyncErrors} disabled={dateError}>
            Search
          </Button>
        </Grid>
      </GridTableHeader>
      <Typography className="error" color={theme.palette.error.main}>
        {errorMsg}
      </Typography>

      <GridTableHeader>
        <Grid item xs={2} className="cell">
          <Typography>Date</Typography>
        </Grid>
        <Grid item xs={3} className="cell">
          <Typography>Operation Id</Typography>
        </Grid>
        <Grid item xs={2} className="cell">
          <Typography>Category</Typography>
        </Grid>
        <Grid item xs={5} className="cell">
          <Typography>Message</Typography>
        </Grid>
      </GridTableHeader>

      <LoadingIndicator show={loadingEmployeeSyncErrors}>
        {employeeSyncData?.map((log, i) => {
          return (
            <GridTableRow key={log.operationId + "_" + i}>
              <Grid item xs={2} className="cell">
                <Typography>
                  {dayjs(log.timeStamp).format("YYYY-MM-DD hh:mm:ss")}
                </Typography>
              </Grid>
              <Grid item xs={3} className="cell">
                <Typography>{log.operationId}</Typography>
              </Grid>
              <Grid item xs={2} className="cell">
                <Typography>{categoryMapper(log.category)}</Typography>
              </Grid>
              <Grid item xs={5} className="cell">
                <Typography>{log.message}</Typography>
              </Grid>
            </GridTableRow>
          );
        })}
      </LoadingIndicator>
    </CardContainer>
  );
}
