import { Typography } from "@mui/material";
import { theme } from "../../../Common/UI/styles/theme";

export default function ReleaseTwoPointThree() {
  return (
    <>
      <Typography variant="h6" className="release-title" color={theme.palette.error.main}>
        RELEASE 2.3 - March 10th, 2025
      </Typography>
      <ul>
        <li>
          <b>NEW</b> Authorization Menu
          <ul>
            <li style={{ listStyleType: "circle" }}>
              With this release, all Custom Application Access is not managed within the Diablo Dashboard. The Authorization menu will only be
              accessible by the Admin group and should have ability to manage: Roles, Groups & Users for all Custom Developed Applications
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          Dashboard Home – Latest Run Integration Status
          <ul>
            <li style={{ listStyleType: "circle" }}>Employee Discount Card Details Uploaded to Paytronix</li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          Reports <b>NEW</b> “CrunchTime Weekly Sales Diff” Report–
          <ul>
            <li style={{ listStyleType: "circle" }}>
              Under Reports, you can now select “CrunchTime Weekly Sales Diff” under Report Type then select From/To dates. This report will display
              Sales variances between POS & CrunchTime within selected date ranges{" "}
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          Store 411 -
          <ul>
            <li style={{ listStyleType: "circle" }}>
              <b>NEW</b> Addition of two non-store locations (Manor & HQ)
            </li>
            <ul>
              <li>
                As requested by network team, under Store 411 you will be able to see Manor & HQ as locations. Those location will only have Network
                tab available
              </li>
            </ul>
          </ul>
        </li>
      </ul>
    </>
  );
}
