import { AuthenticatedTemplate } from "@azure/msal-react";
import { ReactElement, useContext, useEffect, useState } from "react";
import { ApplicationLocationContext } from "../../../Common/UI/utils/ApplicationState";
import { pageNames } from "../../../authConfig";
import { PageContainer } from "../../../Common/UI/components/PageContainer";
import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CardContainer } from "../../../Common/UI/components/CardContainer";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import AssignmentTab from "../Assignments/AssignmentTab";
import GroupsTab from "../Group/GroupsTab";
import { ApplicationDto } from "../Interfaces";
import PermissionsTab from "../Permissions/PermissionsTab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReportsTab from "../Assignments/ReportsTab";

export default function ApplicationHome(): ReactElement {

  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.authorization],
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <PageContainer>
        <DashboardAuthorizationTabsView />
      </PageContainer>
    </AuthenticatedTemplate>
  );
}

function DashboardAuthorizationTabsView() {
  const { appName } = useParams();
  const location = useLocation();
  const [application, setApplication] = useState<ApplicationDto | null>();

  //tabs
  const [value, setValue] = useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [refreshPermissionsTrigger, setRefreshPermissionsTrigger] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!location.state && !application) {
      //TODO fetchStoresInfo();
    } else if (location.state) {
      setApplication(location.state as ApplicationDto);
    }
  }, [location.state, application]);

  return (
    <CardContainer title={appName + " Authorization"}>
      <Link to={"/authorization"} className="back-to-list-link">
        <ArrowBackIcon />
        Back to applications
      </Link>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label=""
        >
          <Tab
            label="Permissions"
            id={`simple-tab-1`}
            aria-controls={`simple-tabpanel-1`}
          />
          <Tab
            label="Groups"
            id={`simple-tab-2`}
            aria-controls={`simple-tabpanel-2`}
          />
          <Tab
            label="Permissions Associations"
            id={`simple-tab-3`}
            aria-controls={`simple-tabpanel-3`}
          />
          <Tab
            label="Reports"
            id={`simple-tab-4`}
            aria-controls={`simple-tabpanel-4`}
          />
        </Tabs>
      </Box>
      <PermissionsTab
        value={value}
        index={0}
        applicationName={appName!}
        setRefreshPermissionsTrigger={setRefreshPermissionsTrigger}
      />
      <GroupsTab
        value={value}
        index={1}
        applicationName={appName!}
        setRefreshTrigger={setRefreshTrigger}
      />
      <AssignmentTab
        value={value}
        index={2}
        applicationName={appName!}
        refreshTrigger={refreshTrigger}
        refreshPermissionsTrigger={refreshPermissionsTrigger}
      />
      <ReportsTab
        value={value}
        index={3}
        applicationName={appName!}
        refreshTrigger={refreshTrigger}
      />
    </CardContainer>
  );
}
