import { ChangeEvent, ReactElement, useEffect, useRef, useState } from "react";
import { CustomTabPanel } from "../../../Common/UI/components/CustomTabPanel";
import { AgGridReact } from "ag-grid-react";

import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { ApplicationGraph } from "../Interfaces";
import { config } from "../../../authConfig";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import { LoadingIndicator } from "../../../Common/UI/components/Loading";
import styles from "./Styles/ReportsTab.module.css";

export default function ReportsTab({
  value,
  index,
  applicationName,
  refreshTrigger,
}: {
  value: number;
  index: number;
  applicationName: string;
  refreshTrigger: number;
}): ReactElement {
  const execute = useFetchMsal();

  const [reportData, setReportData] = useState<ApplicationGraph>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [groupsColDef, setGroupsColDef] = useState<ColDef[]>([
    { field: "groupName", headerName: "Group Name" },
    {
      field: "assignedRole",
      headerName: "Assigned Roles",
    },
  ]);

  const [usersColDef, setUsersColDef] = useState<ColDef[]>([
    { field: "userEmail", headerName: "User Email" },
    {
      field: "userGroupName",
      headerName: "User Group",
    },
    {
      field: "userRole",
      headerName: "User Role",
    },
  ]);

  const [menuAnchorElement, setMenuAnchorElement] =
    useState<HTMLElement | null>(null);

  const gridRefGroups = useRef<AgGridReact>(null);

  const gridRefUsers = useRef<AgGridReact>(null);

  // State for dropdown selection
  const [selectedOption, setSelectedOption] = useState<"Groups" | "Users">(
    "Groups"
  );

  useEffect(() => {
    console.log("fetch applicationGraph");
    fetchData();
  }, [refreshTrigger]);

  const fetchData = async () => {
    setIsLoading(true);
    const url = config.rootAPIList.applicationGraph.replace(
      "{applicationName}",
      applicationName
    );

    execute("GET", `${config.rootAPIUrl}${url}`)
      .then((result: ApplicationGraph) => {
        setReportData(result);
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedOption === "Groups" && gridRefGroups.current?.api) {
      gridRefGroups.current.api.sizeColumnsToFit();
    } else if (selectedOption === "Users" && gridRefUsers.current?.api) {
      gridRefUsers.current.api.sizeColumnsToFit();
    }
  }, [selectedOption, reportData]);

  const extractGroupRoles = (reportData: ApplicationGraph | undefined) => {
    if (!reportData) return [];

    const result: { groupName: string; assignedRole: string }[] = [];

    reportData.groups.forEach((group) => {
      group.permissions.forEach((permission) => {
        result.push({
          groupName: group.name,
          assignedRole: permission.name,
        });
      });
    });

    return result;
  };

  const extractUserRoles = (reportData: ApplicationGraph | undefined) => {
    if (!reportData) return [];

    // Step 1: Extract all unique user emails
    const uniqueEmails = new Set<string>();

    // Extract emails from groups.users
    reportData.groups.forEach((group) => {
      group.users.forEach((user) => {
        uniqueEmails.add(user.email);
      });
    });

    // Extract emails from permissions.users
    reportData.permissions.forEach((permission) => {
      permission.users.forEach((email) => {
        uniqueEmails.add(email);
      });
    });

    // Step 2: Create the result array
    const result: {
      userEmail: string;
      userGroupName: string;
      userRole: string;
    }[] = [];

    // Step 3: Iterate over unique emails and populate the result
    uniqueEmails.forEach((email) => {
      // Find roles from groups
      reportData.groups.forEach((group) => {
        if (group.users.some((user) => user.email === email)) {
          group.permissions.forEach((permission) => {
            result.push({
              userEmail: email,
              userGroupName: group.name,
              userRole: permission.name,
            });
          });
        }
      });

      // Find roles from permissions
      reportData.permissions.forEach((permission) => {
        if (permission.users.includes(email)) {
          result.push({
            userEmail: email,
            userGroupName: "", // Empty group name for permissions array
            userRole: permission.permission.name,
          });
        }
      });
    });

    return result;
  };

  // Handle dropdown change
  const handleDropdownChange = (
    event: SelectChangeEvent<"Groups" | "Users">
  ) => {
    const value = event.target.value as "Groups" | "Users";
    setSelectedOption(value);
  };

  const handleExportAsCsvButtonClick = () => {
    if (selectedOption === "Groups") {
      gridRefGroups.current?.api.exportDataAsCsv({
        fileName: `export_${dayjs(dayjs())
          .format("YYYY_MM_DD_HH_mm_ss")
          .toString()}`,
      });
    } else {
      gridRefUsers.current?.api.exportDataAsCsv({
        fileName: `export_${dayjs(dayjs())
          .format("YYYY_MM_DD_HH_mm_ss")
          .toString()}`,
      });
    }
  };

  const handleColumnsMenuChangeGroups = (e: ChangeEvent<HTMLInputElement>) => {
    setGroupsColDef((prev) =>
      prev.map((col: ColDef) =>
        col.field === e.target.name ? { ...col, hide: !col.hide } : col
      )
    );
  };

  const handleColumnsMenuChangeUsers = (e: ChangeEvent<HTMLInputElement>) => {
    setUsersColDef((prev) =>
      prev.map((col: ColDef) =>
        col.field === e.target.name ? { ...col, hide: !col.hide } : col
      )
    );
  };

  const onInitialDataRender = async () => {
    if (selectedOption === "Groups") {
      gridRefGroups.current?.api.sizeColumnsToFit();

      await gridRefGroups.current?.api.setColumnFilterModel(
        "basicInfo.status",
        {
          filter: "Opened",
          type: "equals",
        }
      );
      gridRefGroups.current?.api.onFilterChanged();
    } else {
      gridRefUsers.current?.api.sizeColumnsToFit();

      await gridRefUsers.current?.api.setColumnFilterModel("basicInfo.status", {
        filter: "Opened",
        type: "equals",
      });
      gridRefUsers.current?.api.onFilterChanged();
    }
  };

  return (
    <LoadingIndicator show={isLoading}>
      <CustomTabPanel value={value} index={index}>
        <Box sx={{ mb: 1 }}>
          {/* Dropdown Menu */}
          <FormControl sx={{ minWidth: 120, mr: 2 }}>
            <InputLabel id="data-select-label">Report</InputLabel>
            <Select
              labelId="data-select-label"
              id="data-select"
              value={selectedOption}
              label="Report"
              onChange={handleDropdownChange} // Use the correct event type
            >
              <MenuItem value="Groups">Groups</MenuItem>
              <MenuItem value="Users">Users</MenuItem>
            </Select>
          </FormControl>

          <Button
            startIcon={<ViewWeekIcon />}
            onClick={(e) => setMenuAnchorElement(e.currentTarget)}
          >
            Columns
          </Button>
          <Button
            sx={{
              ml: 1,
              minWidth: "max-content",
            }}
            startIcon={<DownloadIcon />}
            onClick={handleExportAsCsvButtonClick}
          >
            Export as CSV
          </Button>
          <Menu
            open={Boolean(menuAnchorElement)}
            anchorEl={menuAnchorElement}
            onClose={() => setMenuAnchorElement(null)}
          >
            {selectedOption === "Groups"
              ? groupsColDef.map((col: ColDef) => (
                  <MenuItem key={col.field}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!col.hide}
                          name={col.field}
                          onChange={handleColumnsMenuChangeGroups}
                        />
                      }
                      label={col.headerName}
                    />
                  </MenuItem>
                ))
              : usersColDef.map((col: ColDef) => (
                  <MenuItem key={col.field}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!col.hide}
                          name={col.field}
                          onChange={handleColumnsMenuChangeUsers}
                        />
                      }
                      label={col.headerName}
                    />
                  </MenuItem>
                ))}
          </Menu>
        </Box>
        {selectedOption === "Groups" ? (
          <div className={`ag-theme-quartz ${styles.gridContainer}`}>
            <AgGridReact
              loading={!groupsColDef.length || isLoading}
              defaultColDef={{
                filter: "agTextColumnFilter",
                filterParams: {
                  buttons: ["apply", "clear"],
                  closeOnApply: true,
                },
              }}
              pagination
              paginationPageSize={20}
              rowData={extractGroupRoles(reportData)}
              columnDefs={groupsColDef}
              ref={gridRefGroups}
              onGridReady={() => gridRefGroups.current?.api.sizeColumnsToFit()}
              onFirstDataRendered={onInitialDataRender}
            />
          </div>
        ) : (
          <div className={`ag-theme-quartz ${styles.gridContainer}`}>
            <AgGridReact
              loading={!usersColDef.length || isLoading}
              defaultColDef={{
                filter: "agTextColumnFilter",
                filterParams: {
                  buttons: ["apply", "clear"],
                  closeOnApply: true,
                },
              }}
              pagination
              paginationPageSize={20}
              rowData={extractUserRoles(reportData)}
              columnDefs={usersColDef}
              ref={gridRefUsers}
              onGridReady={() => gridRefUsers.current?.api.sizeColumnsToFit()}
              onFirstDataRendered={onInitialDataRender}
            />
          </div>
        )}
      </CustomTabPanel>
    </LoadingIndicator>
  );
}
