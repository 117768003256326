import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { config } from "../../../authConfig";
import { LoadingIndicator } from "../../../Common/UI/components/Loading";
import { CardContainer } from "../../../Common/UI/components/CardContainer";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import React from "react";
import { PermissionDto } from "../Interfaces";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupPermission from "./GroupPermission";
import UserPermission from "./UserPermission";

export interface SimpleDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children: ReactNode;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, children, title } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} className="simple-dialog">
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <>{title}</>
          </Grid>
          <Grid item xs={2} className="close-icon-container">
            <CloseIcon onClick={onClose} className="close-btn" />
          </Grid>
        </Grid>
      </DialogTitle>
      {children}
    </Dialog>
  );
}

export default function ManageAssignment({
  applicationName,
  availablePermissions,
  refresh,
}: {
  applicationName: string;
  refresh: () => void;
  availablePermissions: PermissionDto[];
}): ReactElement {
  const execute = useFetchMsal();
  const [isGroup, setIsGroup] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "baseline",
          }}
        >
          <Button
            className={"create-group-btn"}
            onClick={() => {
              handleClickOpen();
            }}
          >
            <>
              <AssignmentIndIcon />
              Manage Users
            </>
          </Button>
        </Grid>
      </Grid>
      <SimpleDialog
        title={`Manage Permissions`}
        key="create-application-key"
        open={open}
        onClose={handleClose}
      >
        <LoadingIndicator show={loading}>
          <CardContainer className="permissions-assignment-popup">
           
              <UserPermission
                applicationName={applicationName}
                permissionsAvailable={availablePermissions}
                refresh={refresh}
                handleClose={handleClose}
              />
            
          </CardContainer>
        </LoadingIndicator>
      </SimpleDialog>
    </Box>
  );
}

function ChooseTypeAssociation({ isGroup, setIsGroup }:{ isGroup: boolean, setIsGroup: React.Dispatch<any> }) {
  return (
    <Box sx={{ paddingBottom:"20px"}}>
      <FormControl fullWidth >
        <InputLabel id="manage-create-new-label">Create new</InputLabel>
        <Select
          labelId="manage-create-new-label"
          id="manage-create-new-type"
          value={isGroup === true ? "true": "false"}
          label="Create new"
          required
          onChange={(event: SelectChangeEvent) => {
            debugger
            var result = event.target.value === "true" ? true : false;
            setIsGroup(result);
          }}
          
        >
          <MenuItem key={"manage-create-new-select-group"} value={"true"}>
            Group Permission Association
          </MenuItem>
          <MenuItem key={"manage-create-new-select-user"} value={"false"}>
            User Permission Association
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
