import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import "./CreatePermission.css";
import CloseIcon from "@mui/icons-material/Close";
import { config } from "../../../authConfig";
import { LoadingIndicator } from "../../../Common/UI/components/Loading";
import { CardContainer } from "../../../Common/UI/components/CardContainer";
import useFetchMsal from "../../../Common/UI/hook/useFetchMsal";
import AddIcon from "@mui/icons-material/Add";
import React from "react";

export interface SimpleDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children: ReactNode;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, children, title } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} className="simple-dialog">
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <>{title}</>
          </Grid>
          <Grid item xs={2} className="close-icon-container">
            <CloseIcon onClick={onClose} className="close-btn" />
          </Grid>
        </Grid>
      </DialogTitle>
      {children}
    </Dialog>
  );
}

function CreatePermission({
  appName,
  refresh,
}: {
  appName: string;
  refresh: () => void;
}): ReactElement {
  const execute = useFetchMsal();

  const [open, setOpen] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  function handleCreatePermission() {
    const payload = {
      name,
      description,
    };

    setLoading(true);

    const url = config.rootAPIList.createPermission.replace(
      "{applicationName}",
      appName
    );
    execute("POST", `${config.rootAPIUrl}${url}`, payload)
      .then(() => {
        setLoading(false);
        refresh();
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        handleClose();
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDescription(description);
    setName(name);
    setLoading(false);
  };

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "baseline",
          }}
        >
          <Button
            className={"create-app-btn"}
            onClick={() => {
              handleClickOpen();
            }}
          >
            <>
              <AddIcon sx={{ marginRight: "10px" }} />
              Create permission
            </>
          </Button>
        </Grid>
      </Grid>
      <SimpleDialog
        title="Create Permission"
        key="create-application-key"
        open={open}
        onClose={handleClose}
      >
        <LoadingIndicator show={loading}>
          <CardContainer className="application-dialog">
            <Grid container>
              <Grid item xs={12} className={""} sx={{ paddingBottom: "15px" }}>
                <TextField
                  fullWidth
                  required
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "100%" },
                    height: "120px",
                  }}
                  noValidate
                  autoComplete="off"
                  className="application-description"
                >
                  <div>
                    <TextField
                      id="standard-multiline-static"
                      label="Description"
                      required
                      multiline
                      rows={4}
                      defaultValue={description}
                      variant="standard"
                      className="txt-area"
                      onChange={(ev) => {
                        setDescription(ev.target.value);
                      }}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
            <div className="spacer-small"></div>
            <Grid container className={"application-item"}>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <Button disabled={loading || description.length === 0 || name.length === 0} onClick={handleCreatePermission}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </CardContainer>
        </LoadingIndicator>
      </SimpleDialog>
    </Box>
  );
}
export default CreatePermission;
