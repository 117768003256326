import { AuthenticatedTemplate } from "@azure/msal-react";
import { ReactElement, useContext, useEffect } from "react";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import "./EmployeeSyncErrors.css";
import { pageNames } from "../../authConfig";
import EmployeeSyncErrors from "./EmployeeSyncErrors";
import { PageContainer } from "../../Common/UI/components/PageContainer";

function EmployeeSyncToPosErrorsView(): ReactElement {
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.integrations, pageNames.intEmployeeSync],
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <PageContainer>
        <EmployeeSyncErrors/>
      </PageContainer>
    </AuthenticatedTemplate>
  );
}

export default EmployeeSyncToPosErrorsView;
