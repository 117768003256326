import { ReactElement, useContext, useEffect, useState } from "react";
import { appRoles, pageNames, config } from "../../authConfig";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { PageContainer } from "../../Common/UI/components/PageContainer";
import { Box, Grid, Typography } from "@mui/material";
import { CardContainer } from "../../Common/UI/components/CardContainer";
import { GridTableHeader } from "../../Common/UI/components/GridTableHeader";
import { LoadingIndicator } from "../../Common/UI/components/Loading";
import useFetchMsal from "../../Common/UI/hook/useFetchMsal";
import { GridTableRow } from "../../Common/UI/components/GridTableRow";
import CreateApplication from "./Application/CreateApplication";
import { Link } from "react-router-dom";
import { ApplicationDto } from "./Interfaces";
import React from "react";
import DeleteConfirmation from "./DeleteConfirmation";
import EditApplication from "./Application/EditApplication";

export default function AuthorizationHome(): ReactElement {
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.authorization],
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <PageContainer>
        <DashboardAuthorizationApplications />
      </PageContainer>
    </AuthenticatedTemplate>
  );
}

function DashboardAuthorizationApplications() {
  const execute = useFetchMsal();

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [applications, setApplications] = useState<ApplicationDto[] | null>(
    null
  );
  const [loadingApplications, setLoadingApplications] =
    useState<boolean>(false);

  const getApplications = () => {
    setLoadingApplications(true);

    const url = config.rootAPIList.applications;

    execute("GET", `${config.rootAPIUrl}${url}`)
      .then((result) => {
        setApplications(result);
        setErrorMsg("");
      })
      .catch((error) => {
        setErrorMsg(JSON.parse(error).message);
        console.log(error);
      })
      .finally(() => {
        setLoadingApplications(false);
      });
  };

  useEffect(() => {
    getApplications();
  }, []);

  const deleteHandler = async (item: { name: string }) => {
    try {
      const url = config.rootAPIList.deleteApplication.replace(
        "{applicationName}",
        item.name
      );

      await execute("DELETE", `${config.rootAPIUrl}${url}`);

      getApplications();
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return (
    <CardContainer title="Authorization">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          paddingBottom: "30px",
        }}
      >
        <CreateApplication refresh={getApplications} />
      </Box>

      <GridTableHeader>
        <Grid item xs={4}>
          <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
            Name
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
            Description
          </Typography>
        </Grid>
      </GridTableHeader>
      <LoadingIndicator show={loadingApplications}>
        {applications?.map((app, i) => {
          return (
            <GridTableRow key={app.name}>
              <Grid item xs={4}>
                <Link to={`/authorization/${app.name}`} state={app}>
                  <Typography>{app.name}</Typography>
                </Link>
              </Grid>
              <Grid item xs={4}>
                <Typography>{app.description}</Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  paddingLeft: "10px",
                  display: "flex",
                  justifyContent: "right",
                  gap: "10px",
                }}
              >
                <EditApplication
                  app_name={app.name}
                  app_description={app.description}
                  refresh={getApplications}
                />
                <DeleteConfirmation
                  deleteHandler={deleteHandler}
                  message={`Are you sure you want to delete application ${app.name}`}
                  item={app}
                />
              </Grid>
            </GridTableRow>
          );
        })}
      </LoadingIndicator>
    </CardContainer>
  );
}
