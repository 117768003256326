import { ReactElement, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridSortItem, GridEventListener } from "@mui/x-data-grid";
import "./LRSGrid.css";
import { Cell } from "./CrunchTimeReportInterfaces/Cell";

export interface CrunchTimeDate {
  date: string;
  sales: number;
}

export interface CrunchTimeDateData {
  location: string;
  storeName: string;
  data: CrunchTimeDate[];
}

function CrunchTimeWeeklySalesDiffGrid({
  crunchTimeDatesData,
  coloredCellsChange,
  onColoredCellsChange,
  setAddedCellsParent,
  setRemovedCellsParent,
}: {
  crunchTimeDatesData: CrunchTimeDateData[] | undefined;
  coloredCellsChange: Cell[];
  onColoredCellsChange: (cells: Cell[]) => void;
  setAddedCellsParent: (cells: Cell[]) => void;
  setRemovedCellsParent: (cells: Cell[]) => void;
}): ReactElement {
  const [coloredCells, setColoredCells] = useState<Cell[]>([]);
  const [initialColoredCellsCopy, setInitialColoredCellsCopy] = useState<Cell[]>([]);
  const [addedCells, setAddedCells] = useState<Cell[]>([]);
  const [removedCells, setRemovedCells] = useState<Cell[]>([]);

  useEffect(() => {
    setColoredCells(coloredCellsChange);
    setInitialColoredCellsCopy(coloredCellsChange);
  }, []);

  useEffect(() => {
    setAddedCellsParent(addedCells);
    setRemovedCellsParent(removedCells);
  }, [addedCells, setAddedCellsParent, removedCells, setRemovedCellsParent]);

  const additionalDateColumns: GridColDef[] =
    crunchTimeDatesData?.[0]?.data
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .map((item) => ({
        field: item.date.replace(/ /g, ""),
        headerName: item.date.split("T")[0],
        flex: 1,
        minWidth: 80,
        type: "number",
        renderCell: (params) => {
          const isColored = coloredCells.some((cell) => cell.RowId === params.id.toString() && cell.Field === params.field);

          return (
            <div
              style={{
                backgroundColor: isColored ? "palegoldenrod" : "transparent",
                width: "105%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <span style={{ padding: "6px" }}>{params.value}</span>
            </div>
          );
        },
      })) ?? [];

  const columns: GridColDef[] = [
    { field: "location", headerName: "Store Number", flex: 1, minWidth: 80 },
    { field: "storeName", headerName: "Store Name", flex: 1, minWidth: 150 },
    ...additionalDateColumns,
  ];

  const rows = crunchTimeDatesData?.map((item) => {
    const row: { location: string; storeName: string; [key: string]: any } = {
      location: item.location,
      storeName: item.storeName,
    };

    item.data.forEach((crunchTime) => {
      row[crunchTime.date.replace(/ /g, "")] = crunchTime.sales;
    });

    return row;
  });

  const [sortModel, setSortModel] = useState<GridSortItem[]>([{ field: "location", sort: "asc" }]);

  const handleCellClick: GridEventListener<"cellClick"> = (params) => {
    if (params.field === "location" || params.field === "storeName") return;

    const clickedCell = { RowId: params.id.toString(), Field: params.field };

    setColoredCells((prev) => {
      const isAlreadyColored = prev.some((cell) => cell.RowId === clickedCell.RowId && cell.Field === clickedCell.Field);
      let updatedCells;

      if (isAlreadyColored) {
        // If colored, remove it
        updatedCells = prev.filter((cell) => !(cell.RowId === clickedCell.RowId && cell.Field === clickedCell.Field));

        // Only add to removedCells if it was originally colored in the DB
        setRemovedCells((prevRemoved) => {
          if (initialColoredCellsCopy.some((cell) => cell.RowId === clickedCell.RowId && cell.Field === clickedCell.Field)) {
            return [...prevRemoved, clickedCell];
          }
          return prevRemoved;
        });

        // Remove from addedCells if it was previously added
        setAddedCells((prevAdded) => prevAdded.filter((cell) => !(cell.RowId === clickedCell.RowId && cell.Field === clickedCell.Field)));
      } else {
        // Only add to `addedCells` if it wasn't originally colored from the DB
        if (!initialColoredCellsCopy.some((cell) => cell.RowId === clickedCell.RowId && cell.Field === clickedCell.Field)) {
          setAddedCells((prevAdded) => {
            if (prevAdded.some((cell) => cell.RowId === clickedCell.RowId && cell.Field === clickedCell.Field)) {
              return prevAdded;
            }
            return [...prevAdded, clickedCell];
          });
        }

        // Remove from removedCells if it was previously removed
        setRemovedCells((prevRemoved) => prevRemoved.filter((cell) => !(cell.RowId === clickedCell.RowId && cell.Field === clickedCell.Field)));

        updatedCells = [...prev, clickedCell];
      }

      onColoredCellsChange(updatedCells);
      return updatedCells;
    });
  };

  if (!crunchTimeDatesData || crunchTimeDatesData.length === 0) {
    return <></>;
  }

  return (
    <Box sx={{ width: "100%", height: "calc(100vh - 300px)" }}>
      <DataGrid
        className="lrsCalendar"
        columns={columns}
        rows={rows || []}
        getRowId={(row) => row.location}
        initialState={{
          pagination: { paginationModel: { pageSize: 100 } },
        }}
        pageSizeOptions={[50, 100, 200]}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        onCellClick={handleCellClick}
        disableRowSelectionOnClick
      />
    </Box>
  );
}

export default CrunchTimeWeeklySalesDiffGrid;
