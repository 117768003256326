import { AuthenticatedTemplate } from "@azure/msal-react";
import { ReactElement, useContext, useEffect } from "react";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import "./HelpContainer.css";
import { pageNames } from "../../authConfig";
import { PageContainer } from "../../Common/UI/components/PageContainer";
import HelpContainer from "./HelpContainer";

function HelpView(): ReactElement {
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.helpPage],
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <PageContainer><HelpContainer/></PageContainer>
    </AuthenticatedTemplate>
  );
}

export default HelpView;
