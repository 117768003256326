import { AuthenticatedTemplate } from "@azure/msal-react";
import { ReactElement, useContext, useEffect } from "react";
import { ApplicationLocationContext } from "../../Common/UI/utils/ApplicationState";
import { pageNames } from "../../authConfig";
import ReportingPage from "./Components/ReportingPage";

function DailyReporting(): ReactElement {
  const { state, setState } = useContext(ApplicationLocationContext);

  useEffect(() => {
    setState({
      path: [pageNames.reports],
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <ReportingPage />
    </AuthenticatedTemplate>
  );
}

export default DailyReporting;
